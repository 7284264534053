import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from "../../components/CustomButtons/Button.js";
import { rejectlist, xlsetdata } from '../../actions/admin';

import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle"

import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { toastAlert } from '../../lib/toastAlert'
import { TextField } from "@material-ui/core";
import ReactDatatable from "@ashvin27/react-datatable";
import * as moment from "moment";
import { read, utils, writeFile } from 'xlsx';
import Papa from "papaparse";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    // onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const history = useHistory();
    const customStyles = customStyle();
    const classesSearch = useToolbarStyles();
    // const [data, setData] = React.useState(0);
    // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);

    // const [withdraw, setwithdraw] = useState([]);
    const [responsive, setresponsive] = useState(true);
    const [searchid, setSearchid] = useState(0);
    const [userDetails, setUser] = useState([]);

    // const [datafile, setdatafile] = useState('')
    // alert(OpenSelfie)

    const [filedata, setfiledata] = useState([]);
    const [filename, setfilename] = useState("");
// alert(filename)
    //     const handleImport = ($event) => {
    //         const files = $event.target.files;
    //         if (files.length) {
    //             const file = files[0];
    //             setfilename(file.name)
    //             const reader = new FileReader();
    //             reader.onload = (event) => {
    //                 const wb = read(event.target.result);
    //                 const sheets = wb.SheetNames;
    // console.log(wb);
    //                 if (sheets.length) {
    //                     const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
    //                     setfiledata(rows)
    //                 }
    //             }
    //             reader.readAsArrayBuffer(file);
    //         }
    //     }

    const handleExport = async () => {

        try {

            const result = await xlsetdata(
                filedata
            )
            if (result.status == true) {
                window.location.reload()
            }




        } catch (err) {
            console.log(err);

        }

    }

    const handleImport = (event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        console.log(event.target.files[0],"lllllllllll");
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                console.log(results.data)
                setfiledata(results.data)
                setfilename(event.target.files[0].name)
            },
        });
    };

    console.log(filedata, "llll");


    useEffect(() => {
        getAll();
    }, [searchid])




    const getAll = async () => {

        var reqdata = {
            search: searchid
        }
        console.log(reqdata);
        var res = await rejectlist(reqdata);
        console.log(res);
        if (res?.result != null) {

            setUser(res.result);
        } else {

            setUser([])
        }

    }





    const columns = [
        {
            key: "sno",
            text: "S.No",
            className: "userID",
            align: "center",
            sortable: false,
            cell: (record, index) => (
                <div>
                    <p align="center">{index + 1}</p>
                </div>
            ),
        },
        {
            key: "CardNumber",
            text: "Card Number",
            className: "CardNumber",
            align: "center",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{record.CardNumber}</p>
                </div>
            ),
        },

        {
            key: "virtualNumber",
            text: "virtual Number",
            className: "virtualNumber",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{record.virtualNumber}</p>
                </div>
            ),
        },



        // {
        //     key: "referealAmtBos",
        //     text: "Amount",
        //     className: "planAmount",
        //     align: "left",
        //     sortable: false,

        //     cell: (record) => (
        //         < div >
        //             {record.amount}
        //         </div >
        //     ),
        // },

        // {
        //     key: "useremail",
        //     text: "Date",
        //     className: "userID",
        //     align: "left",
        //     sortable: false,
        //     cell: (record) => (
        //         <div>
        //             <p align="center">{moment(record.createdAt).format('MMMM,Do YYYY, hh:mm A')}</p>
        //         </div>
        //     ),
        // },
        {
            key: "status",
            text: "status",
            className: "status",
            align: "left",
            width: "10px",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{record.status == true ? <span class="badge badge-pill badge-success">True</span> : <span class="badge badge-pill badge-danger"> False</span>}

                    </p>

                </div >
            ),
        },
        // {
        //     key: "View",
        //     text: "View",
        //     className: "View",
        //     align: "left",
        //     width: "10px",
        //     sortable: false,
        //     cell: (record) => (
        //         <div>
        //             <Link to={`/view-nfc/${record._id}`}><VisibilityIcon style={{ color: "#109ebf" }} /></Link>
        //         </div>
        //     ),
        // },


    ];

    const halfAddrShow = (addr) => {
        if (addr) {
            return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
        } else {
            return "";
        }
    };

    function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }



    // const handlefilesubmit = () => {
    //     // async getExcel_data(filepath, sheetName) {
    //     var workbook = new Excel.Workbook();
    //     var data = workbook.csv.readFile(datafile).then(function () {
    //         var rowData = [];
    //         var worksheet = workbook.getWorksheet(datafile.name);
    //         var rows = worksheet.rowCount;
    //         for (var i = 1; i <= rows; i++) {
    //             var row = worksheet.getRow(i);
    //             rowData[i - 1] = new Array(row.cellCount);
    //             for (var j = 1; j <= row.cellCount; j++) {
    //                 rowData[i - 1][j - 1] = row.getCell(j).value;
    //             }
    //         }
    //         return rowData;
    //     });
    //     //2D array returned
    //     return data;
    //     //   }
    // }


    const configdata = {
        page_size: 5,
        length_menu: [5, 10, 20, 50],
        filename: "Users",
        no_data_text: "No user found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };



    return (
        <div className={classes.root}>
            <div>
                {/* <div className="custom-file">
                    <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
                </div> */}


                <Button
                    type="warning"
                    style={{
                        width: "10%",
                        marginTop: "20px",
                        background: "#1b29f5",
                        borderRadius: "5px"
                    }}
                    variant="contained"
                    component="label"
                >
                    Select File
                    <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport} accept=".csv"
                    />
                    {/* <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label> */}
                </Button>
                <p>{filename}</p>
            </div>
            {/* <div className="col-md-6"> */}
            {filename == "" ? "" :
                <button onClick={handleExport} className="btn btn-primary float-left">
                    Import <i className="fa fa-download"></i>
                </button>
            }
            {/* </div> */}
            <br></br>
            <br></br>
            {/* <br></br> */}
            <Paper className={classes.paper}>
                <Toolbar
                    className={clsx(classesSearch.root, {
                        [classesSearch.highlight]: selected.length > 0,
                    })}
                >
                    <Typography
                        className={classesSearch.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >

                        NFC Card List
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        label="Search "
                        variant="outlined"
                        style={{ width: "700px" }}
                        onChange={(e) => setSearchid(e.target.value)}
                    />
                    <br></br>

                </Toolbar>

                <ReactDatatable
                    responsive={responsive}
                    config={configdata}
                    records={userDetails}
                    columns={columns}
                    onPageChange={pageChange()}
                />

            </Paper>


        </div >
    );
} 