import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import { nfcCarduser } from "../../actions/admin";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle";
import { Link, useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import ReactDatatable from "@ashvin27/react-datatable";
import * as moment from "moment";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        ></Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const history = useHistory();
    const customStyles = customStyle();
    const classesSearch = useToolbarStyles();
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);

    const [responsive, setresponsive] = useState(true);
    const [search, setSearch] = useState("");
    const [userDetails, setUser] = useState([]);

    useEffect(() => {
        getAll();
    }, [search]);

    const getAll = async () => {
        var reqdata = {
            search: search,
        };
        var res = await nfcCarduser(reqdata);
        console.log(res, "res");
        if (res.result != null) {
            setUser(res.result);
        } else {
            setUser([]);
        }
    };

    const columns = [
        {
            key: "sno",
            text: "S.No",
            className: "userID",
            align: "left",
            sortable: false,
            cell: (record, index) => (
                <div>
                    <p align="center">{index + 1}</p>
                </div>
            ),
        },
        {
            key: "username",
            text: "Username",
            className: "userID",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{record.address}</p>
                </div>
            ),
        },
        {
            key: "card",
            text: "Card Number",
            className: "card",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{record.cardNumber}</p>
                </div>
            ),
        }, 
        // {
        //     key: "cardtype",
        //     text: "Card Type",
        //     className: "cardtype",
        //     align: "left",
        //     sortable: false,
        //     cell: (record) => (
        //         <div>
        //             <p align="center">{record.cardtype}</p>
        //         </div>
        //     ),
        // },

        {
            key: "date",
            text: "Date",
            className: "userID",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">
                        {moment(record.cardDate).format("MMMM,Do YYYY, hh:mm A")}
                    </p>
                </div>
            ),
        },
    ];

    //   const halfAddrShow = (addr) => {
    //     if (addr) {
    //       return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    //     } else {
    //       return "";
    //     }
    //   };

    function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    const configdata = {
        page_size: 5,
        length_menu: [5, 10, 20, 50],
        filename: "Users",
        no_data_text: "No user found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar
                    className={clsx(classesSearch.root, {
                        [classesSearch.highlight]: selected.length > 0,
                    })}
                >
                    <Typography
                        className={classesSearch.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        NFC User List
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        label="Search "
                        variant="outlined"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Toolbar>

                <ReactDatatable
                    responsive={responsive}
                    config={configdata}
                    records={userDetails}
                    columns={columns}
                    onPageChange={pageChange()}
                />
            </Paper>
        </div>
    );
}
