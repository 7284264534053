// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon

} from "@material-ui/icons";
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DashboardIcon from '@material-ui/icons/Dashboard';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AdjustIcon from '@material-ui/icons/Adjust';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },

  /* Dashboard */
  // {
  //   id: "dashboard",
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   isSideMenu: true,
  //   isEdit: false,
  //   isRestricted: 'single'
  // },


  {
    path: "/list",
    id: "admin",
    name: "Admin Management",
    icon: List,
    isSideMenu: true,
    isEdit: false,
    // isRestricted: 'multi',
    child: [
      {
        id: "add",
        path: "/addadmin",
      },
      {
        id: "edit",
        path: "/edit/:userId",
      },
    ],
  },


  /* Dashboard */
  {
    path: "#",
    id: "dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Dashboard Lists",
        path: "/dashboard",
        icon: DashboardIcon,
      },

    ]

  }, {
    path: "#",
    id: "user",
    name: "User List",
    icon: Person,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "User List",
        path: "/userlist",
        icon: Person,
      },

    ]

  },



  // {
  //   path: "#",
  //   id: "faq",
  //   name: "FAQ ",
  //   icon: QuestionAnswerIcon,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Add FAQ",
  //       path: "/addFaq",
  //       icon: QuestionAnswerIcon,
  //     },
  //     {
  //       name: "List FAQ",
  //       path: "/Faq",
  //       icon: QuestionAnswerIcon,
  //     },
  //   ],
  // },
  {
    path: "#",
    id: "kyc",
    name: "KYC List",
    icon: VerifiedUserIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "KYC",
        path: "/kyc",
        icon: VerifiedUserIcon,
      },
    ],
  },

  {
    path: "#",
    id: "cms",
    name: "Cms Banner",
    icon: ViewCarouselIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Cms Update",
        path: "/cms",
        icon: ViewCarouselIcon,
      },
    ],
  },
  {
    path: "#",
    id: "Action",
    name: "Mining and staking list",
    icon: AdjustIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Staking",
        path: "/staking-list",
        icon: AdjustIcon,
      }, {
        name: "Mining",
        path: "/mining-list",
        icon: AdjustIcon,
      },
    ],
  },
  {
    path: "#",
    id: "withdraw-history",
    name: "Withdraw History",
    icon: MonetizationOnIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Mine withdraw history",
        path: "/mining-Withdraw",
        icon: History,
      }, {
        name: "Stake withdraw history",
        path: "/stak-Withdraw",
        icon: History,
      }, {
        name: "Company mine reward",
        path: "/mining-Owner",
        icon: History,
      }, {
        name: "Company stake reward",
        path: "/StakeOwner-Withdraw",
        icon: History,
      }, {
        name: "Stake mine level reward",
        path: "/Busd-Withdraw",
        icon: History,
      }, {
        name: "Stake mine busd reward",
        path: "/withdraw",
        icon: History,
      },
    ],
  },

  {
    path: "#",
    id: "Ads",
    name: "Graph Data",
    icon: Equalizer,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Graph Data",
        path: "/ads-plan",
        icon: Equalizer,
      },

    ],
  },

  {
    path: "#",
    id: "AddFeatures",
    name: "Add Features",
    icon: AddCircleOutlinedIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "King Features",
        path: "/kingfeatures",
        icon: AddCircleOutlinedIcon,
      }, {
        name: "Queen Features",
        path: "/queenfeatures",
        icon: AddCircleOutlinedIcon,
      },
    ],
  },
  ///***
  // {
  //   path: "#",
  //   id: "AddNfcFeatures",
  //   name: "Add NFC Card Features",
  //   icon: AddCircleOutlineOutlinedIcon,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "NFC Card King Features",
  //       path: "/NfcKingFeature",
  //       icon: AddCircleOutlineOutlinedIcon,
  //     },
  //     {
  //       name: "NFC Card Queen Features",
  //       path: "/NfcQueenFeature",
  //       icon: AddCircleOutlineOutlinedIcon,
  //     },
  //   ],
  // }, 
  {
    path: "#",
    id: "commissionhis",
    name: "Commission History",
    icon: HistoryEduIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Commission History",
        path: "/commission-history",
        icon: HistoryEduIcon,
      },
    ],
  },

  {
    path: "#",
    id: "NfcUserlist",
    name: "NFC Card list",
    icon: PointOfSaleIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "NFC Merchant List",
        path: "/NfcUserlist",
        icon: PointOfSaleIcon,
      },
      {
        name: "NFC User List",
        path: "/nfccard-user",
        icon: CreditScoreIcon,
      },
    ],
  }, {
    path: "#",
    id: "nfc",
    name: "NFC Card Number",
    icon: VerifiedUserIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      // {
      //   name: "NFC Request",
      //   path: "/nfc-request",
      //   icon: VerifiedUserIcon,
      // }, 
      {
        name: "NFC card Number List",
        path: "/nfc-card-list",
        icon: VerifiedUserIcon,
      },
    ],
  },
  ///***
  {
    path: "#",
    id: "Nfctransaction",
    name: "Nfc User Transaction",
    icon: History,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Cash",
        path: "/nfc-transaction",
        icon: History,
      },
      {
        name: "Busd",
        path: "/busd-transaction",
        icon: History,
      },
    ],
  }, {
    path: "#",
    id: "merchantTransaction",
    name: "Merchant Transaction",
    icon: History,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Cash",
        path: "/merchant-cash-transaction",
        icon: History,
      },
      {
        name: "Busd",
        path: "/merchant-busd-transaction",
        icon: History,
      },
    ],
  },
  {
    path: "#",
    id: "d&wtransaction",
    name: "Deposit & Withdraw Transaction",
    icon: History,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Merchant D&W",
        path: "/merchant-deposite-and-withdraw",
        icon: History,
      },
      {
        name: "user D&W",
        path: "/user-deposite-and-withdraw",
        icon: History,
      },
    ],
  },

  {
    path: "#",
    id: "nfcsettings",
    name: "NFC Settings",
    icon: SettingsIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "QBT Value ",
        path: "/qbt-value",
        icon: SettingsIcon,
      }, {
        name: "Update Commission",
        path: "/commission",
        icon: SettingsIcon,
      },
      //  {
      //   name: "Update NFC Card Price",
      //   path: "/UpdateNfcCardPrice",
      //   icon: SettingsIcon,
      // },
      {
        name: "Request CASH-QBT",
        path: "/manualpay-qbt",
        icon: SettingsIcon,
      }, {
        name: "CMS Content",
        path: "/cms-Content",
        icon: SettingsIcon,
      }

    ]
  },
  {
    path: "#",
    id: "settings",
    name: "Settings",
    icon: SettingsIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [

      {
        name: "Direct Referral Percentage",
        path: "/directreferal",
        icon: SettingsIcon,
      }, {
        name: "Referral Earning",
        path: "/referalearning",
        icon: SettingsIcon,
      }, {
        name: "King Reward",
        path: "/king-reward",
        icon: SettingsIcon,
      }, {
        name: "Queen Reward",
        path: "/queen-reward",
        icon: SettingsIcon,
      }, {
        name: "Pooling List",
        path: "/poolinglist",
        icon: SettingsIcon,
      }, {
        name: "Update Token Price",
        path: "/TokenPrice",
        icon: SettingsIcon,
      }, {
        name: "Update Plan Price",
        path: "/UpdatePlanPrice",
        icon: SettingsIcon,
      }, {
        name: "Update Staking limit",
        path: "/UpdateStack",
        icon: SettingsIcon,
      },
      //  {
      //   name: " Update Withdraw Limit",
      //   path: "/withdraw-limit",
      //   icon: Person,
      // },

    ],
  },


  // {
  //   path: "#",
  //   id: "DirectReferalPercentage",
  //   name: "Direct Referal Percentage",
  //   icon: GroupAddIcon,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Direct Referal Percentage",
  //       path: "/directreferal",
  //       icon: GroupAddIcon,
  //     },
  //   ],
  // },





  // {
  //   path: "#",
  //   id: "referalearning",
  //   name: "Referal Earning",
  //   icon: AttachMoneyTwoToneIcon,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Referal Earning",
  //       path: "/referalearning",
  //       icon: AttachMoneyTwoToneIcon,
  //     },
  //   ],
  // },

  // {
  //   path: "#",
  //   id: "kingreward",
  //   name: "Rewards",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "King Reward",
  //       path: "/king-reward",
  //       icon: Person,
  //     },{
  //       name: "Queen Reward",
  //       path: "/queen-reward",
  //       icon: Person,
  //     },
  //   ],
  // },



  // {
  //   path: "#",
  //   id: "PoolingList",
  //   name: "Pooling List",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Pooling List",
  //       path: "/poolinglist",
  //       icon: Person,
  //     },
  //   ],
  // },

  //   {
  //   path: "#",
  //   id: "Updatetokenprice",
  //   name: "Update Token Price",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Updatetokenprice",
  //       path: "/TokenPrice",
  //       icon: Person,
  //     },
  //   ],
  // },
  //  {
  //   path: "#",
  //   id: "UpdatePlanPrice",
  //   name: "Update Plan Price",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Update Plan Price",
  //       path: "/UpdatePlanPrice",
  //       icon: Person,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   id: "UpdateStack",
  //   name: "Staking limit",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Update Staking limit",
  //       path: "/UpdateStack",
  //       icon: Person,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   id: "UpdateWithdraw",
  //   name: "Withdraw Limit",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: " Update Withdraw Limit",
  //       path: "/withdraw-limit",
  //       icon: Person,
  //     },
  //   ],
  // },

  //Swagger Docs
  // {
  //   path: "#",
  //   id: "swagger",
  //   name: "Documentation",
  //   icon: DashboardIcon,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Swagger Doc",
  //       path: "/swaggerdoc",
  //       icon: DashboardIcon,
  //     },

  //   ]

  // }
];

export default dashboardRoutes;
