import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import dexbot from "../../ABI/dexbot.json";
import config from "../../actions/config";
import {
    cmscontentupdate,
    getcmscontentdata
} from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty.js";
// import { toast } from "react-toastify";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none",
    },
};

// toaster config
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const initialFormValue = {
    cmsContent: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
    const customStyles = customStyle();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState(initialFormValue);
    const [Account, setAccount] = useState()
    const [FormSubmitLoading, setFormSubmitLoading] = useState("done")
    const [getdataid, setgetdataid] = useState("")
    const { cmsContent } = formData

    const onChange1 = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // console.log(getdata, "cmsContent");






    useEffect(() => {
        acc();
        getcmsdata()
    }, []);

    const acc = async () => {
        const web3 = new Web3(window.ethereum);
        let accounts = await web3.eth.requestAccounts();
        setAccount(accounts);
    };


    const getcmsdata = async () => {
        const result = await getcmscontentdata()
        console.log(result, "result");
        setgetdataid(result?.result?.result[0]?._id)
        const dataoffinal = {
            cmsContent: result.result.result[0]?.cmsContent
        }
        setFormData(dataoffinal)

    }

    const handlesubmit = async () => {
        try {


            const reqdata = {
                cmsContent: cmsContent,
                id: getdataid
            }
            const result = await cmscontentupdate(reqdata)
            toast.success("Update Successfully");
            console.log(result);


        } catch (err) {
            console.log(err);
        }

    }



    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>

                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>
                                Update CMS Content
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <textarea name="cmsContent" onChange={onChange1} placeholder="Enter CMs Content" style={{ width: '90%' }} value={formData.cmsContent}></textarea>
                                </GridItem>

                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={handlesubmit} type="submit" disabled={FormSubmitLoading == "processing"}>
                                {FormSubmitLoading == "processing" && (
                                    <i
                                        class="fa fa-spinner fa-spin"
                                        aria-hidden="true"
                                        id="circle"
                                    ></i>
                                )}
                                Update
                            </Button>
                        </CardFooter>

                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
