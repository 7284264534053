import { createBrowserHistory } from 'history';
import Web3 from "web3";
import config1 from "../lib/config"

// export default process.env.BROWSER && createBrowserHistory(); 
import { toast } from "react-toastify";

let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};


const web3 = new Web3(Web3.givenProvider);

export const idcrtnetwork = async () => {
    let chaninid = await web3.eth.getChainId();
    if (chaninid != config1.network) {
        toast.warning(config1.message, toasterOption);
        return false
    }
}

//  idcrtnetwork();

export default createBrowserHistory();