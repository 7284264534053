import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import ListGroup from 'react-bootstrap/ListGroup';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";

import config from "../../actions/config.js";

import * as moment from "moment";

import "react-phone-input-2/lib/style.css";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";

import { getStakandmining } from "../../actions/admin";
import { TextField } from "@material-ui/core";
import form from "../../reducers/form.js";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        textDecoration: "none",
    },
};

// toaster config
toast.configure();
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};



const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
    const customStyles = customStyle();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [FormValue, setFormValue] = useState({})



    const { id } = useParams();


    // console.log(faqId, "data");

    const getFaq = async () => {
        var result = await getStakandmining(id);
        // // console.log('test',test);
        console.log(result, "result");


        // // console.log("----formdata", result.name)
        setFormValue(result.result);
    };


    useEffect(() => {
        getFaq()
    }, [])




    return (
        // formData.map(formData => ()
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form
                            className={classes.form}
                            noValidate
                        //   onSubmit={handleFormSubmit}
                        >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>User Details</h4>
                                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
                            </CardHeader>
                            <CardBody>



                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <ListGroup variant="flush" >
                                            <ListGroup.Item style={{ border: 'none' }}>User Id  </ListGroup.Item>
                                            <ListGroup.Item style={{ border: 'none' }}>Hash             </ListGroup.Item>
                                            <ListGroup.Item style={{ border: 'none' }}>Referral Address             </ListGroup.Item>
                                            <ListGroup.Item style={{ border: 'none' }}>Withdraw Amount  </ListGroup.Item>
                                            {/* <ListGroup.Item style={{ border: 'none' }}>Plan   </ListGroup.Item> */}
                                            <ListGroup.Item style={{ border: 'none' }}>Date    </ListGroup.Item>
                                        </ListGroup>

                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={1}>

                                        <ListGroup variant="flush" >
                                            <ListGroup.Item style={{ border: 'none', width: '30px' }}>  :  </ListGroup.Item>
                                            <ListGroup.Item style={{ border: 'none', width: '30px' }}>  :  </ListGroup.Item>
                                            <ListGroup.Item style={{ border: 'none', width: '30px' }}>  :  </ListGroup.Item>
                                            <ListGroup.Item style={{ border: 'none', width: '30px' }}> :           </ListGroup.Item>
                                            {/* <ListGroup.Item style={{ border: 'none', width: '30px' }}>  :           </ListGroup.Item> */}
                                            <ListGroup.Item style={{ border: 'none', width: '30px' }}>  :           </ListGroup.Item>
                                        </ListGroup>

                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={8}>

                                        <ListGroup variant="flush">
                                            <ListGroup.Item style={{ border: 'none' }}>  {FormValue.useraddress == '' ? "-" : FormValue.useraddress} </ListGroup.Item>


                                            <ListGroup.Item style={{ border: 'none' }}> {FormValue.hash == '' ? "-" : <a href={`https://bscscan.com/tx/${FormValue.hash}`} target="_blank">{FormValue.hash}</a>} </ListGroup.Item>
                                            <ListGroup.Item style={{ border: 'none' }}>  {FormValue.referrerAddress == '' ? "-" : FormValue.referrerAddress} </ListGroup.Item>

                                            <ListGroup.Item style={{ border: 'none' }}>  {FormValue.amount == null ? "-" : FormValue.amount}-QBT</ListGroup.Item>
                                            {/* <ListGroup.Item style={{ border: 'none' }}> {FormValue.plan == null ? "-" : FormValue.plan}</ListGroup.Item> */}
                                            {/* <ListGroup.Item style={{ border: 'none' }}> {FormValue.createdAt == null ? "-" : FormValue.createdAt}</ListGroup.Item> */}
                                            <ListGroup.Item style={{ border: 'none' }}> {FormValue.createdAt == null ? "-" : moment(FormValue.createdAt).format('MMMM,Do YYYY, hh:mm A')}</ListGroup.Item>

                                        </ListGroup>

                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit" href="/queenbotsAdmin/staking-list">
                                    Back
                                </Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
