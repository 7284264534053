import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import dexbot from "../../ABI/dexbot.json";
import config from "../../actions/config";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {
  getadd,
  updateadd
  // getkingReward,
  // updatekingReward,
  // getReferalEarningdetail,
  // updateReferalearning
} from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";
import { idcrtnetwork } from "../../lib/history"

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  name: "",
  data: ""
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [adminnameset, setAdminName] = useState("");
  const [adminid, setadminid] = useState("");
  const [admindata, setadmindata] = useState("");
  const [minStakeAmount, setminStakeAmount] = useState("")
  const [maxStakeAmount, setmaxStakeAmount] = useState("")
  const [FormSubmitLoading, Set_FormSubmitLoading] = useState("")
  const [Account, setAccount] = useState()
  const [activedata, setActivedata] = useState(false)//false
  const [Miningdata, setminigdata] = useState(false)//false
  const [planName, setplanName] = useState("")


  // console.log(activedata);

  const toggleDisplay = () => {
    if (activedata == false) {
      setActivedata(true);
      // alert("1st");
    } else {
      setActivedata(false);
      // alert("2nd");
    }
  };
  const toggle = () => {
    if (Miningdata == false) {
      setminigdata(true);
      // alert("true");
    } else {
      setminigdata(false);
      // alert("false");
    }
  };



  // console.log(adminid);
  // console.log(adminnameset, "adminnamesetadminnamesetadminnameset");

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === "period") {
      const { id, value } = e.target;
      setAdminName(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      // console.log(formDatass, "formDatass");
      setFormData(formDatass);
    } else if (e.target.name === "Duration") {
      const { id, value } = e.target;
      setadmindata(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      // console.log(formDatass, "formDatass");
      setFormData(formDatass);
    } else if (e.target.name === "max") {
      const { id, value } = e.target;
      setmaxStakeAmount(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      // console.log(formDatass, "formDatass");
      setFormData(formDatass);
    } else if (e.target.name === "planname") {
      const { id, value } = e.target;
      setplanName(value);
      // setValidateError({});
      // let formDatass = { ...formData, ...{ [id]: value } };
    } else {
      const { id, value } = e.target;
      setminStakeAmount(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      // console.log(formDatass, "formDatass");
      setFormData(formDatass);
    }



  };

  const { name, data } = formData;


  useEffect(() => {
    acc();
  }, []);

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    setAccount(accounts);
  };



  const handleFormSubmit = async (e) => {
    e.preventDefault();


    if (Account != config.admin_address) {
      toast.error("connect your valid account", toasterOption);
      return false;
    }
    const iscrtnet = await idcrtnetwork()
    // console.log(iscrtnet);
    if (iscrtnet == false) {
        return false
    }

    try {
      Set_FormSubmitLoading("processing")
      const web3 = new Web3(window.ethereum);

      const BEPContract = new web3.eth.Contract(
        dexbot,
        config.contract_address
      );


      await BEPContract.methods.add(adminnameset, admindata, minStakeAmount, maxStakeAmount, activedata, Miningdata).send({
        from: config.admin_address,
      });

      let reqData = {
        rewardPercentage: adminnameset ? adminnameset : name,
        duration: admindata,
        minStakeAmount: minStakeAmount,
        maxsatck: maxStakeAmount,
        isActve: activedata,
        isMine: Miningdata,
        planName: planName,
        id: adminid,
      };
      // console.log(reqData, "adminid");

      const { status, message, error, result } = await updateadd(reqData);
      // getadmindata()
      if (status) {
        toastAlert("success", message, "Admin");
        history.push("/poolinglistr");
        Set_FormSubmitLoading("done")
      } else if (error) {
        setValidateError(error);
        Set_FormSubmitLoading("done")
      }
    } catch (err) {
      // console.log(err.length);
      toast.error("Transaction rejected by user", toasterOption);
      Set_FormSubmitLoading("done")
    }
  };



  const getadmindata = async () => {
    var result = null;

    if (result != null) {

      setAdminName(result.result[0]?.rewardPercentage);
      setadmindata(result.result[0]?.duration);
      setminStakeAmount(result.result[0]?.minStakeAmount);
      setadminid(result.result[0]?._id);
      setActivedata(result.result[0]?.isActve)
      setminigdata(result.result[0]?.isMine)
    }
  };
  useEffect(() => {
    //logout(history)
    getadmindata();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              onSubmit={handleFormSubmit}
              noValidate
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Add pooling settings
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Name"
                      onChange={onChange}
                      id="planname"
                      name="planname"
                      value={planName}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />


                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Reward Percentage"
                      onChange={onChange}
                      id="name"
                      name="period"
                      value={adminnameset}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Duration"
                      onChange={onChange}
                      id="Duration"
                      name="Duration"
                      value={admindata}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Minimum Stake Amount"
                      onChange={onChange}
                      id="minStakeAmount"
                      name="minStakeAmount"
                      value={minStakeAmount}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="maximum Stake Amount"
                      onChange={onChange}
                      id="max"
                      name="max"
                      value={maxStakeAmount}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  {/* <div class="form-group row"> */}

                  <div>
                    <label >Staking</label>
                    <br></br>
                    <BootstrapSwitchButton checked={activedata} onstyle="success" offstyle="danger" onlabel="Active" offlabel="Inactive" width={100} onChange={toggleDisplay} />
                  </div>
                  &nbsp;&nbsp;
                  <div>
                    <label>Mining</label>
                    <br></br>
                    <BootstrapSwitchButton checked={Miningdata} onstyle="success" offstyle="danger" onlabel="Active" offlabel="Inactive" width={100} onChange={toggle} />
                  </div>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit" disabled={FormSubmitLoading == "processing"}>
                  {FormSubmitLoading == "processing" && (
                    <i
                      class="fa fa-spinner fa-spin"
                      aria-hidden="true"
                      id="circle"
                    ></i>
                  )}
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
