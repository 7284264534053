import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getFaqList, deleteFaq, getminingwith } from '../../actions/admin';
import Search from "@material-ui/icons/Search";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { toastAlert } from '../../lib/toastAlert'
import { TextField } from "@material-ui/core";
import ReactDatatable from "@ashvin27/react-datatable";
import * as moment from "moment";




const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    // onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const history = useHistory();
    const customStyles = customStyle();
    const classesSearch = useToolbarStyles();
    // const [data, setData] = React.useState(0);
    // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);

    // const [withdraw, setwithdraw] = useState([]);
    const [responsive, setresponsive] = useState(true);
    const [searchid, setSearchid] = useState(0);
    const [userDetails, setUser] = useState([]);





    useEffect(() => {
        getAll();
    }, [searchid])




    const getAll = async () => {

        var reqdata = {
            search: searchid
        }
        var res = await getminingwith(reqdata);
        console.log(res);
        if (res.result != null) {

            setUser(res.result);
        } else {

            setUser([])
        }

    }


    // const isSelected = (name) => selected.indexOf(name) !== -1;


    const columns = [
        {
            key: "sno",
            text: "S.No",
            className: "userID",
            align: "left",
            sortable: false,
            cell: (record, index) => (
                <div>
                    <p align="center">{index + 1}</p>
                </div>
            ),
        }, {
            key: "userID",
            text: "User Address",
            className: "userID",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{halfAddrShow(record.useraddress)}</p>
                </div>
            ),
        },

        {
            key: "accumulationAmountDue",
            text: "Hash",
            className: "planAmount",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{halfAddrShow(record.hash)}</p>
                </div>
            ),
        },



        {
            key: "referealAmtBos",
            text: "Amount",
            className: "planAmount",
            align: "left",
            sortable: false,

            cell: (record) => (
                < div >
                    {record.amount.toFixed(4)}
                </div >
            ),
        },

        {
            key: "useremail",
            text: "Date",
            className: "userID",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{moment(record.createdAt).format('MMMM,Do YYYY, hh:mm A')}</p>
                </div>
            ),
        },

        {
            key: "action",
            text: "View",
            className: "planAmount",
            align: "left",
            width: "10px",
            sortable: false,
            cell: (record) => (
                <div>
                    <Link to={`/mining-view/${record._id}`}><VisibilityIcon style={{ color: "#109ebf" }} /></Link>
                </div>
            ),
        },


    ];

    const halfAddrShow = (addr) => {
        if (addr) {
            return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
        } else {
            return "";
        }
    };

    function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }


    const configdata = {
        page_size: 5,
        length_menu: [5, 10, 20, 50],
        filename: "Users",
        no_data_text: "No user found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };



    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar
                    className={clsx(classesSearch.root, {
                        [classesSearch.highlight]: selected.length > 0,
                    })}
                >
                    <Typography
                        className={classesSearch.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >

                        Mining Withdraw History
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        label="Search User Address"
                        variant="outlined"

                        onChange={(e) => setSearchid(e.target.value)}
                    />
                </Toolbar>

                <ReactDatatable
                    responsive={responsive}
                    config={configdata}
                    records={userDetails}
                    columns={columns}
                    onPageChange={pageChange()}
                />

            </Paper>
        </div>
    );
} 