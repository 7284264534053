import React, { useState, useEffect } from "react";
// @material-ui/core components

// core components
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import dexbot from "../../ABI/dexbot.json";
import config from "../../actions/config";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
    getQueenReward,
    updateQueenReward,

} from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";
import * as moment from "moment";
import { idcrtnetwork } from "../../lib/history"

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none",
    },
};
const rows = [

];

const headCells = [
    // { id: 'Sno', numeric: false, disablePadding: true, label: 'Sno' },
    { id: 'plan', numeric: false, disablePadding: true, label: 'Plan' },
    { id: 'RewardPercentage', numeric: false, disablePadding: true, label: 'Reward Percentage' },

    { id: 'status', numeric: false, disablePadding: true, label: 'Action' },


];
function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>

            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

}
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 76%',
    },
}));

// toaster config
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const initialFormValue = {
    name: "",
    data: ""
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {


    const customStyles = customStyle();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const classesSearch = useToolbarStyles();

    const [field, setfeild] = useState(undefined)
    const [formData, setFormData] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [adminnameset, setAdminName] = useState("");
    const [adminid, setadminid] = useState("");
    const [admindata, setadmindata] = useState("");
    const [FormSubmitLoading, Set_FormSubmitLoading] = useState("")
    const [Account, setAccount] = useState()
    const [features, setfeatures] = useState()
    const [feat, setFeat] = useState()
    const [selected, setSelected] = React.useState([]);
    const [planpercentage, setPlanpercentage] = useState()
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    console.log(feat, planpercentage);


    const onChange = (e) => {
        e.preventDefault();
        if (e.target.name === "period") {
            const { id, value } = e.target;
            setFeat(value);

            let formDatass = { ...formData, ...{ [id]: value } };

            setFormData(formDatass);
        } else {
            const { id, value } = e.target;
            setPlanpercentage(value);

            let formDatass = { ...formData, ...{ [id]: value } };

            setFormData(formDatass);
        }

    };

    const { name, data } = formData;


    const handleClickOpen = () => {
        if (Account != config.admin_address) {
            toast.error("Connect your valid account", toasterOption);
            return false;
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        acc();
    }, []);

    const acc = async () => {
        const web3 = new Web3(window.ethereum);
        let accounts = await web3.eth.requestAccounts();
        setAccount(accounts);
    };



    const handleFormSubmit = async (e) => {
        // e.preventDefault();


        if (Account != config.admin_address) {
            toast.error("connect your valid account", toasterOption);
            return false;
        }
        const iscrtnet = await idcrtnetwork()
        // console.log(iscrtnet);
        if (iscrtnet == false) {
          return false
        }
    

        try {
            Set_FormSubmitLoading("processing")
            const web3 = new Web3(window.ethereum);
            function convert(n) {

                var sign = +n < 0 ? "-" : "",
                  toStr = n.toString();
                if (!/e/i.test(toStr)) {
                  return n;
                }
                var [lead, decimal, pow] = n
                  .toString()
                  .replace(/^-/, "")
                  .replace(/^([0-9]+)(e.*)/, "$1.$2")
                  .split(/e|\./);
                return +pow < 0
                  ? sign +
                      "0." +
                      "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
                      lead +
                      decimal
                  : sign +
                      lead +
                      (+pow >= decimal.length
                        ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
                        : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
              }
        
              const datacalcul = planpercentage * 10 ** 18
              var amt = datacalcul.toString();
              amt = convert(amt);
        // alert(amt)

            const BEPContract = new web3.eth.Contract(
                dexbot,
                config.contract_address
            );

            await BEPContract.methods.updateQueenReward(feat, amt).send({
                from: config.admin_address,
            });

            var reqData = {
                plan: feat,
                PercentageReward: planpercentage,
                id: field,
            };


            console.log(reqData, "adminid");

            const { status, message, error, result } = await updateQueenReward(reqData);
            // getadmindata()
            if (status) {
                toast.success("Updated Successfully", "Admin");
                // history.push("/dashboard");
                Set_FormSubmitLoading("done")
                window.location.reload()
            } else if (error) {
                setValidateError(error);
                Set_FormSubmitLoading("done")
            }
        } catch (err) {
            // console.log(err.length);
            toast.error("Transaction rejected by user", toasterOption);
            Set_FormSubmitLoading("done")
            setfeild("done")

        }
    };

    const editR = (id, paln, Percentage) => {
        if (Account != config.admin_address) {
            toast.error("connect your valid account", toasterOption);
            return false;
        }

        setfeild(id)
        setFeat(paln)
        setPlanpercentage(Percentage)

    }


    const getadmindata = async () => {
        var result = await getQueenReward();

        if (result != null) {

            setfeatures(result.result)
        }
    };
    useEffect(() => {
        //logout(history)
        getadmindata();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Toolbar
                        className={clsx(classesSearch.root, {
                            [classesSearch.highlight]: selected.length > 0,
                        })}
                    >
                        <Typography className={classesSearch.title} variant="h6" id="tableTitle" component="div">
                            Queen Reward </Typography>
                            {/* <div className="page_header">
                <Button size="sm" color="rose" onClick={handleClickOpen}
                 >Add New reward</Button>
              </div> */}
                    </Toolbar>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            // size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}

                                // orderBy={orderBy}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {features && features.map((row, index) => {

                                    return (
                                        <TableRow>

                                            <>
                                                <TableCell align="center"
                                                    scope="row" paddingLeft="19px">
                                                    {index + 1}
                                                </TableCell>
{/* 
                                                {field != row._id ?
                                                    <TableCell align="center" >{row.plan}</TableCell> :
                                                    <TableCell align="center" >
                                                        <input style={{
                                                            padding: "14px",
                                                            background: "darkgray",
                                                            background: '#f2f2f2',
                                                            border: '1px solid #2222',
                                                            textAlign: 'center',
                                                            marginTop: '10px',
                                                            marginLeft: '16px',

                                                            // background: "silver"
                                                        }} value={feat} autoFocus
                                                            name="period" type="text" onChange={onChange}  ></input></TableCell>} */}

                                                {field != row._id ?
                                                    <TableCell align="center">{row.PercentageReward}</TableCell> :
                                                    <TableCell align="center">   <input style={{
                                                        padding: "14px",
                                                        background: "darkgray",
                                                        background: '#f2f2f2',
                                                        border: '1px solid #2222',
                                                        textAlign: 'center',
                                                        marginTop: '10px',
                                                        marginLeft: '16px',

                                                        // background: "silver"
                                                    }} value={planpercentage} autoFocus
                                                        name="input" type="text" onChange={onChange}  ></input></TableCell>}




                                                <TableCell align="center" > {field != row._id ?
                                                    <Button size="sm" color="rose" onClick={() => editR(row._id, row.plan, row.PercentageReward)}>edit</Button> :
                                                    <div>
                                                        <Button size="sm" color="success" onClick={() => handleFormSubmit(row._id)} disabled={FormSubmitLoading == "processing"}>
                                                            {FormSubmitLoading == "processing" && (
                                                                <i
                                                                    class="fa fa-spinner fa-spin"
                                                                    aria-hidden="true"
                                                                    id="circle"
                                                                ></i>
                                                            )}update</Button>  &nbsp; &nbsp; &nbsp; &nbsp; <span size="sm" onClick={() => setfeild("hello")}><i class="fa fa-times" aria-hidden="true"></i></span> </div>}</TableCell>

                                                {/* <TableCell align="center" ><Button size="sm" color="rose">Delete</Button>
                          </TableCell> */}



                                            </>
                                        </TableRow>
                                    )
                                })
                                }

                            </TableBody>
                        </Table>

                    </TableContainer>

                    <TablePagination
                    rowsPerPageOptions={[10,20]}
                    component="div"
                    count={features && features.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                </Paper>
            </div>

            <div>
                <div>

                    <Dialog fullWidth="true" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Add Queen Reward</DialogTitle>
                        <DialogContent>

                            <TextField
                                autoFocus
                                margin="dense"
                                name="period"
                                id="add"
                                onChange={onChange}
                                label="Plan"
                                type="email"

                            />
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            {/* </DialogContent>
            <DialogTitle id="form-dialog-title">Add Feature</DialogTitle>
            <DialogContent> */}

                            <TextField
                                autoFocus
                                margin="dense"

                                id="add"
                                onChange={onChange}
                                label="Reward Percentage"
                                type="email"

                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleFormSubmit} color="primary" disabled={FormSubmitLoading == "processing"}>
                                {FormSubmitLoading == "processing" && (
                                    <i
                                        class="fa fa-spinner fa-spin"
                                        aria-hidden="true"
                                        id="circle"
                                    ></i>
                                )}
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>




        </div>
    );
}
