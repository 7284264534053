import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import config2 from "../../lib/config";

import {
  getKycDetails,
  setapproveKyc,
  setrejectKyc,
} from "../../actions/admin";

import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";

import { Link, useHistory } from "react-router-dom";

import * as moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
// import config from "../../lib/config";
// function createData(FirstName, lastName, Email, phoneNo, accountType) {
//   return { FirstName, lastName, Email, phoneNo, accountType };
// }
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Web3 from "web3";
import config from "../../actions/config";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const rows = [
  // getData('')
  //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
  //   createData('Donut', 452, 25.0, 51, 4.9),
];

const headCells = [
  { id: "category", numeric: false, disablePadding: true, label: "Token" },
  { id: "bidaddress", numeric: true, disablePadding: false, label: "Date" },
  { id: "tokendate", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 76%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
  const classes = useStyles();
  const customStyles = customStyle();
  const history = useHistory();
  const classesSearch = useToolbarStyles();
  const [data, setData] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userDetails, setUser] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [responsive, setresponsive] = useState(true);
  const [open, setOpen] = React.useState(false);

  const [imagedata, setimagedata] = useState();
  const [backsideimg, setbacksideimg] = useState();

  const [Type, setType] = useState();
  const [ProofNumber, setProofNumber] = useState();
  const [iddata, setiddata] = useState();
  const [Selfieiddata, setSelfieiddata] = useState();
  const [selfiemessage, setselfiemessage] = useState("");
  const [idreason, setidreason] = useState("");
  const [IdName, setIdName] = useState()
  const [OpenSelfie, setOpenSelfie] = useState(false);



  const [panfront, setpanfront] = useState();
  const [panback, setpanback] = useState();
  const [panName, setpanName] = useState();
  const [panNumber, setpanNumber] = useState();
  const [panid, setpanid] = useState();
  const [Openpan, setOpenpan] = useState(false);
  const [panreason, setpanreason] = useState("")




  const [OpenAddress, setOpenAddress] = useState(false)
  const [Addressfront, setAddressfront] = useState();
  const [Addressback, setAddressback] = useState();
  const [Addresstype, setAddresstype] = useState();
  const [AddressName, setAddressName] = useState();
  const [AddressNumber, setAddressNumber] = useState();
  const [Addressid, setAddressid] = useState();
  const [Addressreason, setAddressreason] = useState("")

  const [Account, setAccount] = useState()


  const [searchid, setSearchid] = useState(0);
  const [idstatus, setidstatus] = useState("");
  const [selfiestatus, setselfiestatus] = useState("");
  const [addressstatus, setaddressstatus] = useState("");



  console.log(selfiestatus);
  useEffect(() => {
    acc();
  }, []);

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    setAccount(accounts);
  };



  const handleReasonselfie = (event) => {
    setselfiemessage(event.target.value);

    // console.log("value is:", event.target.value);
  };

  const handleidproof = (event) => {
    if (event.target.name == "idproof") {
      setidreason(event.target.value);
    } else if (event.target.name == "pan") {
      setpanreason(event.target.value)
    } else if (event.target.name == "address") {
      setAddressreason(event.target.value)
    }

  };

  function editR(id) {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    if (id != "") {
      history.push("/kycView/" + id);

    }
  }


  const moredetails = (id) => {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    if (id != "") {
      history.push("/userdetail/" + id);

    }
  }


  const handlePanClick = (panfront, panback, panName, panNumber, panId) => {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    setOpenpan(true)
    setpanfront(panfront)
    setpanback(panback)
    setpanName(panName)
    setpanNumber(panNumber)
    setpanid(panId)
  }

  const handleAddressopen = (addressfront, addressback, addressName, type, addressNumber, addressId, status) => {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    setOpenAddress(true)
    setAddressfront(addressfront)
    setAddressback(addressback)
    setAddressName(addressName)
    setAddressNumber(addressNumber)
    setAddressid(addressId)
    setAddresstype(type)
    setaddressstatus(status)
  }


  const handleClickOpen = (idproof, back, type, proof, id, Name, status) => {

    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    setOpen(true);
    setimagedata(idproof);
    setbacksideimg(back);
    setType(type);
    setProofNumber(proof);
    setiddata(id);
    setIdName(Name)
    setidstatus(status)
    // alert(id);
  };



  const handleClickSelfie = (idproof, id, status) => {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    setOpenSelfie(true);
    setimagedata(idproof);
    setSelfieiddata(id);
    setselfiestatus(status)
  };



  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleselfieClose = (e) => {
    e.preventDefault();
    setOpenSelfie(false);
  };
  const handlePanClose = (e) => {
    e.preventDefault();
    setOpenAddress(false)
    setOpenpan(false);
  };




  const approve = async (id, type) => {
    // console.log(id);
    var data = {
      id: id,
      type: type,
      selfieReason: selfiemessage,
      idproofReason: idreason,
      panReason: panreason,
      Addressreason: Addressreason
    };
    // console.log(data);
    const approve = await setapproveKyc(data);
    if (approve) {
      toast.success("Approved Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 900);

    }
  };

  const rejected = async (id, type) => {
    // console.log(id);

    console.log(idreason.length == 0, "idreason");
    if (type == "idproof") {
      if (idreason.length == 0) {
        toast.warning("Please Enter Reason For Rejection");
        return false
      }
    } else if (type == "address") {
      if (Addressreason.length == 0) {
        toast.warning("Please Enter Reason For Rejection");
        return false
      }
    } else if (type == "selfe") {
      if (selfiemessage.length == 0) {
        toast.warning("Please Enter Reason For Rejection");
        return false
      }
    }


    var data = {
      id: id,
      type: type,
      selfieReason: selfiemessage,
      idproofReason: idreason,
      panReason: panreason,
      Addressreason: Addressreason
    };

    const approve = await setrejectKyc(data);
    if (approve) {
      toast.error("Rejected Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 900);

    }
  };

  useEffect(() => {
    getAll();
  }, [searchid]);
  const getAll = async (search = {}) => {
    let filterData = {
      search: searchid
    }
    // if (filterData.search == "") {
    // filterData = {};
    // }
    var test = await getKycDetails(filterData);
    // getplanDetails(filterData);
    // gettokenlist(filterData);
    if (test && test.result) {
      // console.log("tokenlistt", test.result);
      setUser(test.result);
    }
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };


  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const columns = [
    {
      key: "UserId",
      text: "User Id",
      className: "UserId",
      // fontFamily: "sans-serif",
      align: "left",
      width: "130",
      sortable: true,
      cell: (record) => (
        <div>
          <p> {halfAddrShow(record.personalId)}</p>
        </div>
      ),
    },
    {
      key: "phone",
      text: "Phone",
      className: "phone",
      // fontFamily: "sans-serif",
      align: "left",
      width: "130",
      sortable: true,
      cell: (record) => (
        <div>
          <p> {record.Mobile}</p>
        </div>
      ),
    },
    //  {
    //   key: "Pan",
    //   text: "Pan Document",
    //   className: "Pan",
    //   width: "130",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => (
    //     <div>
    //       {/* <p>{record.idproof}</p> */}
    //       <div></div>
    //       <Button
    //         color="rose"
    //         size="sm"
    //         onClick={() =>
    //           handlePanClick(
    //             record.pan_frontImage,
    //             record.pan_backImage,
    //             record.pan_name,
    //             record.pan_number,
    //             record._id
    //           )
    //         }
    //       >
    //         Pan Details
    //       </Button>

    //       <p><div>{record.pan_status == "Verified" ? <span class="badge badge-pill badge-success">Verified</span> : record.pan_status == "Rejected" ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-warning">New</span>}</div></p>

    //     </div>
    //   ),
    // },
    {
      key: "Idproof",
      text: "Identity Document",
      className: "Idproof",
      align: "left",
      width: "130",
      sortable: true,
      cell: (record) => (
        <div>
          {/* <p>{record.idproof}</p> */}
          <div></div>
          <Button
            color="rose"
            size="sm"
            onClick={() =>
              handleClickOpen(
                record.idproof_frontImage,
                record.idproof_backImage,
                record.idproof_type,
                record.idproof_proofNumber,
                record._id,
                record.idproof_Name,
                record.idproof_status
              )
            }
          >
            Id Proof
          </Button>

          <p><div>{record.idproof_status == "Verified" ? <span class="badge badge-pill badge-success">Verified</span> : record.idproof_status == "Rejected" ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-warning">New</span>}</div></p>

        </div>
      ),
    },

    {
      key: "Selfie",
      text: "Selfie With Id-proof",
      className: "Selfie",
      align: "left",
      width: "130",
      sortable: true,
      cell: (record) => (
        <div>
          <Button
            color="rose"
            size="sm"
            onClick={() =>
              handleClickSelfie(record.selfie_frontImage, record._id, record.selfie_status)
            }
          >
            Selfie
          </Button>

          <p><div>{record.selfie_status == "Verified" ? <span class="badge badge-pill badge-success">Verified</span> : record.selfie_status == "Rejected" ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-warning">New</span>}</div></p>
        </div>
      ),
    }, {
      key: "Residentialproof",
      text: "Address Proof",
      className: "Residentialproof",
      align: "left",
      width: "130",
      sortable: true,
      cell: (record) => (
        <div>
          <Button
            color="rose"
            size="sm"
            onClick={() =>
              handleAddressopen(record.Address_frontImage, record.Address_backImage, record.Address_Name, record.Address_type, record.Address_proofNumber, record._id, record.Address_status)
            }
          >
            Address
          </Button>
          <p><div>{record.Address_status == "Verified" ? <span class="badge badge-pill badge-success">Verified</span> : record.Address_status == "Rejected" ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-warning">New</span>}</div></p>
        </div>
      ),
    },
    {
      key: "verification",
      text: "Verification Status",
      className: "Residentialproof",
      align: "center",
      width: 100,
      sortable: true,
      cell: (record) => (
        <div>
          <p>
            {record.selfie_status == "New" && record.idproof_status == "New" && record.Address_status == "New" && record.pan_status == "New"
              ? <span class="badge badge-pill badge-warning">Not-Verified</span>
              : record.selfie_status == "Verified" &&
                record.idproof_status == "Verified" &&
                record.Address_status == "Verified"
                // record.pan_status == "Verified"
                ? <span class="badge badge-pill badge-success">Verified</span>
                : record.selfie_status == "Rejected" &&
                  record.idproof_status == "Rejected" &&
                  record.Address_status == "Rejected"
                  ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-info">Pending</span>}
          </p>
        </div>
      ),
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        // console.log(record, "recordssssss");
        const checkin = record.status;
        // console.log(checkin, "checkineeed");

        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-template-modal"
              className="btn btn-primary btn-sm"
              onClick={() => editR(record._id)}
              style={{ marginRight: "5px" }}
            >
              View
            </button>
            {/* &nbsp;
            <span
            ><i class="fa fa-info-circle" aria-hidden="true" onClick={() => moredetails(record._id)}></i>
            </span> */}
          </Fragment >
        );
      },
    },
  ];

  const configdata = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: false,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
  };
  function pageChange(pageData) {
    // console.log("OnPageChange", pageData);
  }
  return (
    <div>
      <div className={classes.root} style={{ width: '100%', height: '100%' }}>
        <Paper className={classes.paper}>
          <Toolbar
            className={clsx(classesSearch.root, {
              [classesSearch.highlight]: selected.length > 0,
            })}
          >
            <Typography
              className={classesSearch.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              KYC Verification
            </Typography>
            <TextField
              id="outlined-basic"
              label="Search User Address"
              variant="outlined"
              onChange={(e) => setSearchid(e.target.value)}
            />
          </Toolbar>
          <ReactDatatable
            responsive={responsive}
            config={configdata}
            records={userDetails}
            columns={columns}
            onPageChange={pageChange()}
          />
        </Paper>
      </div>
      <div>
        <Dialog
          open={OpenAddress}
          TransitionComponent={Transition}
          keepMounted
          onClose={handlePanClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Address Proof"}
          </DialogTitle>
          <DialogContent>
            <div>
              <div class="form-group">
                <label for="exampleInputEmail1">Document type</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={Addresstype}
                  readOnly
                />
              </div>{" "}
              <div class="form-group">
                <label for="exampleInputEmail1">{Addresstype} Name</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={AddressName}
                  readOnly
                />
              </div>{" "}
              {/* <div class="form-group">
                <label for="exampleInputEmail1">{Addresstype} Number</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={AddressNumber}
                  readOnly
                />
              </div> */}
              <div>
                <label for="exampleInputEmail1">Front Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${Addressfront}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${Addressfront}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}

                />
              </div>
              <div>
                <label for="exampleInputEmail1">Back Side Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${Addressback}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${Addressback}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Reason</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="address"
                  placeholder=""
                  onChange={handleidproof}
                  value={Addressreason}
                />
              </div>
              <DialogActions>
                <Button color="success" onClick={() => approve(Addressid, "address")}>
                  Approve
                </Button>

                {addressstatus == "Verified" ?
                  <Button color="danger" onClick={() => rejected(Addressid, "address")}>
                    Force  Reject
                  </Button> : <Button color="danger" onClick={() => rejected(Addressid, "address")}>
                    Reject
                  </Button>
                }
              </DialogActions>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePanClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={Openpan}
          TransitionComponent={Transition}
          keepMounted
          onClose={handlePanClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Pan Document"}
          </DialogTitle>
          <DialogContent>
            <div>
              <div class="form-group">
                <label for="exampleInputEmail1">Pan Name</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={panName}
                  readOnly
                />
              </div>{" "}
              <div class="form-group">
                <label for="exampleInputEmail1">Pan Number</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={panNumber}
                  readOnly
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Front Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${panfront}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${panfront}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}

                />
              </div>
              <div>
                <label for="exampleInputEmail1">Back Side Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${panback}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${panback}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Reason</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="pan"
                  placeholder=""
                  onChange={handleidproof}
                  value={panreason}
                />
              </div>
              <DialogActions>
                <Button color="success" onClick={() => approve(panid, "pan")}>
                  Approve
                </Button>

                <Button color="danger" onClick={() => rejected(panid, "pan")}>
                  Reject
                </Button>
              </DialogActions>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePanClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Identity Document"}
          </DialogTitle>
          <DialogContent>
            <div>
              <div class="form-group">
                <label for="exampleInputEmail1">Type</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={Type}
                  readOnly
                />
              </div>{" "}
              <div class="form-group">
                <label for="exampleInputEmail1">{Type} Name</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={IdName}
                  readOnly
                />
              </div>{" "}
              <div class="form-group">
                <label for="exampleInputEmail1">{Type} Number</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={ProofNumber}
                  readOnly
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Front Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${imagedata}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${imagedata}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}

                />
              </div>
              <div>
                <label for="exampleInputEmail1">Back Side Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${backsideimg}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${backsideimg}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Reason</label>
                <input
                  type="email"

                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="idproof"
                  placeholder="Reason"
                  onChange={handleidproof}
                  value={idreason}
                />
              </div>
              <DialogActions>
                <Button color="success" onClick={() => approve(iddata, "idproof")}>
                  Approve
                </Button>
                {idstatus == "Verified" ?
                  <Button color="danger" onClick={() => rejected(iddata, "idproof")}>
                    Force  Reject
                  </Button> :
                  <Button color="danger" onClick={() => rejected(iddata, "idproof")}>
                    Reject
                  </Button>
                }
              </DialogActions>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={OpenSelfie}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleselfieClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Selfie With Idproof"}
          </DialogTitle>
          <DialogContent>
            <div>
              <label for="exampleInputEmail1">View Selfie Image</label>
              <Button
                color="rose"
                size="sm"
                href={`${config2.baseUrl}/uploads/${imagedata}`}
                target="_blank"
              >
                View
              </Button>
              <img
                src={`${config2.baseUrl}/uploads/${imagedata}`}
                alt="id proof"
                style={{ width: "100%", height: "100%" }}
              />
              <div>
                <label for="exampleInputEmail1">Reason</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  onChange={handleReasonselfie}
                  value={selfiemessage}
                />
              </div>
              <DialogActions>
                <Button
                  color="success"
                  onClick={() => approve(Selfieiddata, "selfe")}
                >
                  Approve
                </Button>

                {selfiestatus == "Verified" ?
                  <Button
                    color="danger"
                    onClick={() => rejected(Selfieiddata, "selfe")}
                  >
                    Force  Reject
                  </Button> : <Button
                    color="danger"
                    onClick={() => rejected(Selfieiddata, "selfe")}
                  >
                    Reject
                  </Button>
                }
              </DialogActions>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleselfieClose} color="primary">
              close
            </Button>
          </DialogActions>
        </Dialog>
      </div>


    </div>
  );
}
