import React, { useEffect, useState } from "react";
// import { Link, useHistory, useParams } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader.js";

import Card from "react-bootstrap/Card";

import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle";

import { toastAlert } from "../../lib/toastAlert";

import config from "../../actions/config";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Web3 from "web3";
import {
  getNfcfeaturs,
  addNfcfeature,
  updateNfcFeature,
  deleteNfcfeature,
} from "../../actions/admin";
import { toast } from "react-toastify";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    textDecoration: "none",
  },
};
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 76%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const customStyle = makeStyles(customInputStyle);
function NfcKingFeature() {
  const classes = useStyles();

  const [field, setfeild] = useState(undefined);

  const [features, setfeatures] = useState([]);
  const [feat, setFeat] = useState();
  const [open, setOpen] = React.useState(false);

  const [addfeatm, setaddfeatm] = useState();

  const [selected, setSelected] = React.useState([]);
  const classesSearch = useToolbarStyles();
  const [Account, setAccount] = useState();

  const [loarder, setloader] = useState(false)

  useEffect(() => {
    acc();
  }, []);

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    setAccount(accounts);
  };

  const handleClickOpen = () => {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === "add") {
      const { value } = e.target;
      setaddfeatm(value);
    } else if (e.target.name === "input") {
      const { id, value } = e.target;
      setFeat(value);
    }
  };

  const editR = (id, feat) => {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    setfeild(id);
    setFeat(feat);
   
  };

  const deleteR = async (id) => {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    var data = {
      id: id,
      name: "king",
    };

    const { status, message, error, result } = await deleteNfcfeature(data);
    toast.error("Deleted-Successfully", message, "Admin");
    // window.location.reload();
    setloader(true)
  };

  const addfeature = async () => {
    if (Account != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    try {
      console.log(addfeatm, "addfeatm");
      if (addfeatm == undefined) {
        toast.error("Required");
        return false;
      } else {
        var reqdata = {
          name: "king",
          Features: addfeatm,
        };

        const { status, message, error, result } = await addNfcfeature(reqdata);

        if (status) {
          toastAlert("success", message, "Admin");
          setloader(true)
          setOpen(false);
        } else if (error) {
          console.log(error);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateR = async (id) => {
    try {
      console.log(feat, "feat");
      if (feat == "") {
        toast.error("Required");
      } else {
        var reqdata = {
          id: id,
          features: feat,
          name: "king"
        };
        const { status, message, error, result } = await updateNfcFeature(
          reqdata
        );

        if (status) {
          toastAlert("success", message, "Admin");
          setloader(true)
          setfeild(undefined)
        } else if (error) {
        }
      }
    } catch (err) { }
  };

  useEffect(() => {
    Getdata();
  }, [loarder]);

  const Getdata = async () => {
    const reqData = {
      name: "king"
    }
    const datas = await getNfcfeaturs(reqData);
    setfeatures(datas.result?.result);
    console.log(datas, "datas");
    setloader(false)
  };

  return (
    <div>
      <Card style={{ width: "100%", height: "100%" }}>
        <Card.Body>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>NFC Card King Features</h4>
          </CardHeader>

          <Card.Text style={{ textAlign: "justify" }}>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Toolbar
                  className={clsx(classesSearch.root, {
                    [classesSearch.highlight]: selected.length > 0,
                  })}
                >
                  <Typography
                    className={classesSearch.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Features{" "}
                  </Typography>

                  <div className="page_header">
                    <Button size="sm" color="rose" onClick={handleClickOpen}>
                      Add New features
                    </Button>
                  </div>
                </Toolbar>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <TableBody>
                      {features &&
                        features.map((row, index) => {
                          return (
                            <TableRow>
                              {row.Features == null ? (
                                ""
                              ) : (
                                <>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    paddingLeft="19px"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  {field !== row._id ? (
                                    <TableCell align="center">
                                      {row.Features}
                                    </TableCell>
                                  ) : (
                                    <textarea
                                      style={{
                                        padding: "12PX",
                                        background: "#f2f2f2",
                                        border: "1px solid #2222",
                                        textAlign: "center",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                        width: "100%",
                                        background: "silver",
                                      }}
                                      value={feat}
                                      autoFocus
                                      name="input"
                                      type="text"
                                      onChange={onChange}
                                    ></textarea>
                                  )}

                                  {field !== row._id ? (
                                    <TableCell align="center">
                                      <Button
                                        size="sm"
                                        color="rose"
                                        onClick={() =>
                                          editR(row._id, row.Features)
                                        }
                                      >
                                        edit
                                      </Button>
                                    </TableCell>
                                  ) : (
                                    <TableCell align="center">
                                      <Button
                                        size="sm"
                                        onClick={() => updateR(row._id)}
                                        color="success"
                                      >
                                        update
                                      </Button>
                                    </TableCell>
                                  )}

                                  <TableCell align="center">
                                    <Button
                                      size="sm"
                                      onClick={() => deleteR(row._id)}
                                      color="rose"
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>

      <div>
        <div>
          <Dialog
            fullWidth="true"
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add Feature</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="add"
                id="add"
                onChange={onChange}
                label="Features"
                type="email"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={addfeature} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default NfcKingFeature;
