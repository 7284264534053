import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import dexbot from "../../ABI/dexbot.json";
import config from "../../actions/config";
import { getNfcCardprice, updateNfcCardprice } from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";
import { AmpStories } from "@material-ui/icons";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UpdateNfcCardPrice() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [validateError, setValidateError] = useState({});
  const [kingamount, setKingAmount] = useState("");
  const [QueenAmount, setQueenAmount] = useState("");
  const [adminid, setadminid] = useState("");
  const [Account, setAccount] = useState();
  const [FormSubmitLoading, Set_FormSubmitLoading] = useState("");
  const [Queenplan, setQueenplan] = useState("");
  const [FormSubmitLoading1, Set_FormSubmitLoading1] = useState("");
  const [id, Setid] = useState("");
  const [Data, SetData] = useState("");

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === "kingamount") {
      const { value } = e.target;
      setKingAmount(value);
    } else if (e.target.name === "QueenAmount") {
      const { value } = e.target;
      setQueenAmount(value);
    }
  };

  useEffect(() => {
    acc();
    getAll();
  }, []);

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    setAccount(accounts);
  };

  const getAll = async () => {


    const { status, message, error, result } = await getNfcCardprice();
    console.log(result, "result");
    console.log(result[0]?._id, "result?._id");
    if (status) {
      Setid(result[0]?._id);
      setKingAmount(result[0]?.KingPrice);
      setQueenAmount(result[0]?.QueenPrice);
    }
  };

  const handleKingSubmit = async () => {
    console.log(id, "id");
    if (id == undefined) {
      var Id = "undefined";
    } else {
      var Id = id;
    }
    const reqData = {
      name: "king",
      data: kingamount,
      id: Id,
    };
    const { status, message, error, result } = await updateNfcCardprice(
      reqData
    );
    if (status) {
      toastAlert("success", message, "Admin");
      window.location.reload();
    }
  };

  const handleQueenSubmit = async () => {
    console.log(id, "id");

    const reqData = {
      name: "queen",
      data: QueenAmount,
      id: id,
    };
    const { status, message, error, result } = await updateNfcCardprice(
      reqData
    );
    toastAlert("success", message, "Admin");
    window.location.reload();
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* <form
              className={classes.form}
              onSubmit={handleKingSubmit}
              noValidate */}
            {/* > */}
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Update NFC King Card Limit</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="King Limit"
                    onChange={onChange}
                    name="kingamount"
                    value={kingamount}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                type="submit"
                onClick={handleKingSubmit}
                disabled={FormSubmitLoading == "processing"}
              >
                {FormSubmitLoading == "processing" && (
                  <i
                    class="fa fa-spinner fa-spin"
                    aria-hidden="true"
                    id="circle"
                  ></i>
                )}
                Update
              </Button>
            </CardFooter>
            {/* </form> */}
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* <form
              className={classes.form}
              onSubmit={handleQueenSubmit}
              noValidate
            > */}
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Update NFC Queen Card Limit</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Queen Limit"
                    onChange={onChange}
                    name="QueenAmount"
                    value={QueenAmount}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                type="submit"
                onClick={handleQueenSubmit}
                disabled={FormSubmitLoading1 == "processing"}
              >
                {FormSubmitLoading1 == "processing" && (
                  <i
                    class="fa fa-spinner fa-spin"
                    aria-hidden="true"
                    id="circle"
                  ></i>
                )}
                Update
              </Button>
            </CardFooter>
            {/* </form> */}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
