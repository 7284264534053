
import React, { useEffect, useState } from "react";
// import { Link, useHistory, useParams } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader.js";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
// import Table from 'react-bootstrap/Table';
// import { makeStyles } from '@material-ui/core/styles';
// import Button from "../../components/CustomButtons/Button.js";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getFaqList, deleteFaq } from '../../actions/admin';
import Search from "@material-ui/icons/Search";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import { toastAlert } from '../../lib/toastAlert'

import config from "../../actions/config";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as moment from "moment";
import Web3 from "web3";
import { getKingfeaturs, setfeaturesSSS, deletefeature, addfeatures, ads_settings, deleteAds, getadsData } from '../../actions/admin';
import { features } from "process";
import { toast } from "react-toastify";
import { SettingsOverscanOutlined } from "@material-ui/icons";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none",
    },
};
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

function createData(sno, Features) {
    return { sno, Features };
}
const initialFormValue = {
    name: "",
    data: ""
};
const headCells = [
    { id: 'Sno', numeric: false, disablePadding: true, label: 'Sno' },
    { id: 'Question', numeric: false, disablePadding: true, label: 'Question' },
    { id: 'Answers', numeric: false, disablePadding: true, label: 'Answers' },
    { id: 'Date', numeric: false, disablePadding: true, label: 'Date' },
    { id: 'status', numeric: false, disablePadding: true, label: 'Action' },


];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>

            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    // numSelected: PropTypes.number.isRequired,
    // onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    // orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 76%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    // const { numSelected } = props;

    //     return (
    //         // <Toolbar
    //         //     className={clsx(classes.root, {
    //         //         [classes.highlight]: numSelected > 0,
    //         //     })}
    //         // >
    //         // </Toolbar>
    //     );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);
function KingQueenFeatures() {
    const classes = useStyles();
    const [formData, setFormData] = useState(initialFormValue);
    const [field, setfeild] = useState(undefined)
    const [userdata, setkingPlanName] = useState("")

    const [planamount, setkingplanamount] = useState('')
    const [features, setfeatures] = useState()
    const [feat, setFeat] = useState()
    const [open, setOpen] = React.useState(false);

    const [addfeatm, setaddfeatm] = useState()

    const [firstid, setfirstId] = useState()
    const [selected, setSelected] = React.useState([]);
    const classesSearch = useToolbarStyles();
    const [Account, setAccount] = useState()

    const [valuedata, setvaluedata] = useState()
    const [month, setmonth] = useState()



    console.log(features,);

    useEffect(() => {
        acc();
    }, []);

    const acc = async () => {
        const web3 = new Web3(window.ethereum);
        let accounts = await web3.eth.requestAccounts();
        setAccount(accounts);
    };




    const handleClickOpen = () => {
        // if (Account != config.admin_address) {
        //     toast.error("Connect your valid account", toasterOption);
        //     return false;
        // }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const onChange = (e) => {
        e.preventDefault();
        if (e.target.name === "input1") {
            const { id, value } = e.target;
            setFeat(value);

        } else if (e.target.name === "input2") {
            const { id, value } = e.target;
            setmonth(value);

        } else if (e.target.name === "input") {
            const { id, value } = e.target;
            setvaluedata(value)
            // alert(value);
        } else if (e.target.name === "date") {

            const { id, value } = e.target;
            setFeat(value)
            // console.log(value);

        } else if (e.target.name === "month") {

            const { id, value } = e.target;
            setmonth(value)
       
        } else if (e.target.name === "value") {

            const { id, value } = e.target;
            setvaluedata(value)
            // console.log(value);

        }


    }






    const editR = (id, feat, value, month) => {

        // if (Account != config.admin_address) {
        //     toast.error("Connect your valid account", toasterOption);
        //     return false;
        // }
        // inputElement.current.focus();
        setfeild(id)
        setFeat(feat)
        setvaluedata(value)
        setmonth(month)
        // console.log(feat);
    }

    const deleteR = async (id) => {
        // if (Account != config.admin_address) {
        //     toast.error("Connect your valid account", toasterOption);
        //     return false;
        // }
        // console.log(id);
        var data = {
            id: id,

        }

        const { status, message, error, result } = await deleteAds(data);
        toast.error("Deleted-Successfully", message, "Admin");
        window.location.reload();


    }

    const addfeature = async (plan) => {

        if (Account != config.admin_address) {
            toast.error("Connect your valid account", toasterOption);
            return false;
        }
        try {

            var reqdata = {
                date: feat,
                month: month,
                value: valuedata,
                // id: field
            }

            console.log(reqdata);
            const { status, message, error, result } = await ads_settings(reqdata);

                toastAlert("success", message, "Admin");
                window.location.reload();
              
        } catch (err) {
            // console.log(err);
        }
    }



    const updateR = async (id) => {

        try {
            var reqdata = {
                date: feat,
                month: month,
                value: valuedata,
                id: field
            }
            console.log(reqdata);
            const { status, message, error, result } = await ads_settings(reqdata);

            // if (status) {
            toastAlert("success", message, "Admin");
            window.location.reload();
            // Set_FormSubmitLoading("done");
            // } else if (error) {
            // console.log(error);
            // Set_FormSubmitLoading("done");
            // }
        } catch (err) {
            console.log(err);
        }

    }







    useEffect(() => {
        Getdata()
    }, [])

    const Getdata = async () => {


        const datas = await getadsData();
        console.log(datas.result?.data, '9999999999999999999');
        // if (datas.result.result == null) {
        // setkingPlanName(datas.result.result?.PlanName)
        // setkingplanamount(datas.result.result?.PlanAmount)
        // setfirstId(datas.result.result?._id)
        setfeatures(datas.result?.data)
        // } else {
        // alert("nothing")
        // }
    }







    return (
        <div>
            <Card style={{ width: '100%', height: '100%' }}>

                <Card.Body>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Graph Details</h4>
                        {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
                    </CardHeader>

                    <Card.Text style={{ textAlign: "justify" }}>

                        <br>
                        </br>
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <Toolbar
                                    className={clsx(classesSearch.root, {
                                        [classesSearch.highlight]: selected.length > 0,
                                    })}
                                >
                                    <Typography className={classesSearch.title} variant="h6" id="tableTitle" component="div">
                                    Graph List </Typography>

                                    <div className="page_header">
                                        {/* <h2>User List</h2> */}
                                        <Button size="sm" color="rose" onClick={handleClickOpen}>Add New Graph</Button>
                                    </div>
                                </Toolbar>
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        // size={dense ? 'small' : 'medium'}
                                        aria-label="enhanced table"
                                    >

                                        <TableBody>
                                            {features && features.map((row, index) => {

                                                return (




                                                    <TableRow>

                                                        <>
                                                            <TableCell align="center"
                                                                scope="row" paddingLeft="19px">
                                                                {index + 1}
                                                            </TableCell>
                                                            {field !== row._id ?
                                                                <TableCell align="center">{row.date}</TableCell> : <TableCell align="center"> <input style={{
                                                                    padding: '12PX',
                                                                    background: '#f2f2f2',
                                                                    border: '1px solid #2222',
                                                                    textAlign: 'center',
                                                                    marginTop: '10px',
                                                                    marginLeft: '17px',
                                                                    width: '50%',
                                                                    background: "silver"
                                                                }} value={feat} autoFocus
                                                                    name="input1" type="text" onChange={onChange}></input></TableCell>}

                                                            {field !== row._id ?
                                                                <TableCell align="center" >{row.month}</TableCell> : <TableCell align="center" > <input style={{
                                                                    padding: '12PX',
                                                                    background: '#f2f2f2',
                                                                    border: '1px solid #2222',
                                                                    textAlign: 'center',
                                                                    marginTop: '10px',
                                                                    marginLeft: '17px',
                                                                    width: '50%',
                                                                    background: "silver"
                                                                }} value={month} autoFocus
                                                                    name="input2" type="text" onChange={onChange}></input></TableCell>}
                                                            {field !== row._id ?
                                                                <TableCell align="center" >{row.value}</TableCell> : <TableCell align="center" > <input style={{
                                                                    padding: '12PX',
                                                                    background: '#f2f2f2',
                                                                    border: '1px solid #2222',
                                                                    textAlign: 'center',
                                                                    marginTop: '10px',
                                                                    marginLeft: '17px',
                                                                    width: '50%',
                                                                    background: "silver"
                                                                }} value={valuedata} autoFocus
                                                                    name="input" type="text" onChange={onChange}></input></TableCell>}

                                                            {field !== row._id ?
                                                                <TableCell align="center" ><Button size="sm" color="rose" onClick={() => editR(row._id, row.date, row.value, row.month)}>edit</Button></TableCell> : <TableCell align="center" ><Button size="sm" onClick={() => updateR(row._id)} color="success">update</Button></TableCell>}

                                                            <TableCell align="center" ><Button size="sm" onClick={() => deleteR(row._id)} color="rose">Delete</Button>
                                                            </TableCell>



                                                        </>
                                                    </TableRow>
                                                )
                                            })
                                            }

                                        </TableBody>
                                    </Table>

                                </TableContainer>

                                {/* <TablePagination
                                    // rowsPerPageOptions={[5, 10, 25]}
                                    // component="div"
                                    // count={features && features.length}

                                /> */}
                            </Paper>
                        </div>

                    </Card.Text>

                </Card.Body>
            </Card >


            <div>
                <div>

                    <Dialog fullWidth="true" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Add details</DialogTitle>
                        <DialogContent>

                            <TextField
                                autoFocus
                                margin="dense"
                                name="date"
                                id="add"
                                onChange={onChange}
                                label="Date"
                                type="email"
                                fullWidth
                            />

                            <br></br>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="month"
                                id="add"
                                onChange={onChange}
                                label="Month"
                                type="email"
                                fullWidth
                            />
                            <br></br>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="value"
                                id="add"
                                onChange={onChange}
                                label="value"
                                type="email"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={addfeature} color="primary">
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div >
    )
}

export default KingQueenFeatures