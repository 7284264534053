import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import dexbot from "../../ABI/dexbot.json";
import config from "../../actions/config";
import {
    sendabtmanual

} from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty.js";
// import { toast } from "react-toastify";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none",
    },
};

// toaster config
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const initialFormValue = {
    email: "",
    qbt: ""
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
    const customStyles = customStyle();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState(initialFormValue);
    const [Account, setAccount] = useState()
    const [FormSubmitLoading, setFormSubmitLoading] = useState("done")

    const { email, qbt } = formData

    const onChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    const validPositive = (e) => {
        console.log(new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value), 'valllluee')
        if (
            new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };


    console.log(formData, "formData");

    useEffect(() => {
        acc();
    }, []);

    const acc = async () => {
        const web3 = new Web3(window.ethereum);
        let accounts = await web3.eth.requestAccounts();
        setAccount(accounts);
    };

    const handlesubmit = async () => {
        try {
            if (email == "") {
                toast.error("Merchant Email field Must Not Be Empty", toasterOption);
                return false
            } if (qbt == "") {
                toast.error("QBT field Must Not Be Empty", toasterOption);
                return false
            }


            const reqdata = {
                merchantid: email,
                qbtvalue: qbt
            }
            if (window.confirm(`Are You Sure to Send ${qbt} QBT to ${email} ?`)) {
                const result = await sendabtmanual(reqdata)

                console.log(result);
                if (result.status == false) {
                    toast.error(result.message, toasterOption);
                } else {
                    setFormSubmitLoading("processing")
                    setTimeout(() => {

                        toast.success("QBT send successfully", toasterOption);
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);
                        setFormSubmitLoading("done")

                    }, 5000);
                }
            }
        } catch (err) {
            console.log(err);
        }

    }



    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>

                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>
                                Manual Qbt Send
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Email"
                                        onChange={onChange}
                                        name="email"
                                        // value={adminnameset}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <CustomInput
                                        labelText="Qbt"
                                        onChange={onChange}
                                        name="qbt"
                                        // value={adminnameset}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        type="number"
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={handlesubmit} type="submit" disabled={FormSubmitLoading == "processing"}>
                                {FormSubmitLoading == "processing" && (
                                    <i
                                        class="fa fa-spinner fa-spin"
                                        aria-hidden="true"
                                        id="circle"
                                    ></i>
                                )}
                                Send QBT
                            </Button>
                        </CardFooter>

                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
