let key = {};
let Envname = "demo"

if (Envname === "live") {
    const API_URL = 'https://mob.queenbots.com';//DEMO
    key = {
        baseUrl: `${API_URL}`,
        singlesmartContract: "0xd1576F8A6895E17Ae4525061FDF6F430D3AfA3B8",   //live
        network: 4,
    };
}
else if (Envname === "demo") {
    // console.log("Set Production Config")
    const API_URL = 'https://dexbotapi.wealwin.com';//DEMO
    key = {
        baseUrl: `${API_URL}`,
        royaltycontract: "0xd1576F8A6895E17Ae4525061FDF6F430D3AfA3B8",   //demo
        network: 97,
        message: "connect Binance Test Network"
    };

} else {

    const API_URL = 'http://localhost';
    key = {
        baseUrl: `${API_URL}:3544`,
        royaltycontract: "0xd1576F8A6895E17Ae4525061FDF6F430D3AfA3B8",   //local
        network: 97,
        message: "connect Binance Test Network"
    };
}


export default key;