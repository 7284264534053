import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import Web3 from "web3";
import config from "../../actions/config";
import { toast } from "react-toastify";

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

// import action
import { getAllAdmin, deleteAdmin } from "../../actions/admin";
import { toastAlert } from "../../lib/toastAlert";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Button from "../../components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const useStyles = makeStyles(styles);

const CoinWithdrawRequest = (props) => {
  const classes = useStyles();

  // state
  const [data, setData] = useState([]);
  const [selfieOpen, setselfieOpen] = useState(false);
  const [deleteid, setdeleteid] = useState("")

  const rows = data;


  const handleClickSelfie = (id) => {

    // if (Account != config.admin_address) {
    //   toast.error("connect your valid account", toasterOption);
    //   return false;
    // }
    setdeleteid(id)
    setselfieOpen(true);
    // setOpenselfie(selfie);
    // setselfiemessage(reason);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 280,
      // type: 'date',
      headerAlign: "left",
      align: "left",
      editable: false,
      // valueGetter:(params)=>`${moment(params.row.date).format('MMMM Do YYYY, hh:mm:ss a')}`
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
      // type: 'date',
      headerAlign: "left",
      align: "left",
      editable: false,
      // valueGetter:(params)=>`${moment(params.row.date).format('MMMM Do YYYY, hh:mm:ss a')}`
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 280,
      // type: 'date',
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={"/eidt/" + params.row._id}>
              <EditIcon style={{ color: "#109ebf" }} />
            </Link>
            <Link onClick={() => handleClickSelfie(params.row._id)}>
              <DeleteIcon style={{ color: "#109ebf" }} />
            </Link>
          </Fragment>
        );
      },
    },
  ];


  // function

  useEffect(() => {
    fetchAllAdmin();
  }, []);

  const fetchAllAdmin = async () => {
    const data = await getAllAdmin();
    console.log(data, "Datat");
    setData(data.result);
  };

  const handleDelete = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      let accounts = await web3.eth.requestAccounts();

      if (accounts != config.admin_address) {
        toast.error("Connect your valid account", toasterOption);
        return false;
      }
      // if (window.confirm("Are You Sure Delete?")) {
        const data = await deleteAdmin(deleteid);
        console.log(data, 'Data____');
        // if (status == "success") {
        toastAlert("success", "Delete successfully", "deleteAdmin");
        fetchAllAdmin();
        setselfieOpen(false);

        // }
      // }
    } catch (err) {
      console.log(err);
    }
  };


  const handleselfieClose = (e) => {
    e.preventDefault();
    setselfieOpen(false);

  };

  console.log(data, "dataaaa");
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Admin List</h4>
              <Link to={"/addadmin"} className={classes.btnPrimary}>
                Add Admin
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            {/* <div style={{ height: 600, width: '100%' }}>
                            {
                                data.length > 0   && 
                            
                            <DataGrid pageSize={10} pagination rows={ data } columns={columns} />
                                            }
                        </div> */}
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                getRowId={(row) => row._id}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick
                disableColumnSelector
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
      <div>
        <Dialog
          open={selfieOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleselfieClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Are you sure want to delete"}</DialogTitle>

          {/* <DialogContent>
        <h3>Type :  surya</h3>
        </DialogContent> */}

          {/* <DialogContent>
            <div>
              <h3>Are you sure want to delete</h3>
              <div>

              </div>
            </div>
          </DialogContent> */}
          <DialogActions>
            <Button onClick={handleselfieClose} color="primary">
              Close
            </Button>
            <Button onClick={handleDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default CoinWithdrawRequest;
