import React, { useEffect, useState, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import { getkyclistone, } from "../../actions/admin";


import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        minHeight: 400
    },


    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


function Userdetails() {
    const classes = useStyles();
    const { userid } = useParams();
    const [user, setuser] = useState()

    // console.log(user);




    useEffect(() => {
        getdata()
    }, [])


    const getdata = async () => {
        const userdata = await getkyclistone(userid)

        if (userdata.result != "") {
            setuser(userdata.result)
        }
    }


    return (


        <Card style={{ width: '100%', height: '17cm' }}>

            <Card.Body>
                <Card.Title>User Details</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-6">
                                <Card style={{ width: "31rem", height: "13cm", marginLeft: '-16px' }}>

                                    <Card.Body>
                                        <Card.Title>User Details</Card.Title>
                                        <div class="container">
                                            <div class="row">

                                                <div class="col-sm-4">
                                                    <br />
                                                    <h5>Country:</h5>
                                                    <br />
                                                    <h5>Name:</h5>
                                                    <br />
                                                    <h5>Email:</h5>
                                                    <br />
                                                    <h5>Phone:</h5>
                                                </div>
                                                <div class="col-sm-5">
                                                    <br />
                                                    <h5>{user?.country} </h5>
                                                    <br />
                                                    <h5>{user?.Name}</h5>
                                                    <br />
                                                    <h5>{user?.email}</h5>
                                                    <br />
                                                    <h5>{user?.Mobile}</h5>
                                                </div>
                                            </div>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-sm-6">
                                <Card style={{ width: "31rem", height: "13cm", marginLeft: '-16px' }}>

                                    <Card.Body>
                                        <Card.Title>Account details</Card.Title>

                                        <div class="container">
                                            <div class="row">
                                                <div class="col-sm-5">
                                                    <br />
                                                    <h5>Account Type:</h5>
                                                    <br />
                                                    <h5>Account Name:</h5>
                                                    <br />
                                                    <h5>Account Number:</h5>
                                                    <br />
                                                    <h5>Bank Name:</h5>
                                                    <br />
                                                    <h5>branch:</h5>
                                                    <br />
                                                    <h5>IfscCode:</h5>

                                                </div>
                                                <div class="col-sm-6">
                                                    <br />
                                                    <h5>{user?.accountType} </h5>
                                                    <br />
                                                    <h5>{user?.accountName}</h5>
                                                    <br />
                                                    <h5>{user?.accountNumber}</h5>
                                                    <br />
                                                    <h5>{user?.bankName}</h5>
                                                    <br />
                                                    <h5>{user?.branch}</h5>
                                                    <br />
                                                    <h5>{user?.IfscCode}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            {/* <div class="col-sm-4">
                                <Card style={{ width: "21rem", height: "10cm",marginLeft: '-16px' }}>

                                    <Card.Body>
                                        <Card.Title>Account details</Card.Title>
                                       
                                     
                                    </Card.Body>
                                </Card>
                            </div> */}


                        </div>
                    </div>
                </Card.Text>
                <a class="btn btn-primary" href="/queenbotsAdmin/kyc" role="button">Back</a>
            </Card.Body>
        </Card>


    );
}


export default Userdetails