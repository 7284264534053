import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";

// import './assets/css/material-dashboard-react.CSS'

// import components
import Admin from "./components/layouts/Admin";
import ConditionRoute from "./components/Route/ConditionRoute";

// import pages
import LoginPage from "./pages/LoginPage/Login";

import Dashboard from "./pages/Dashboard/Dashboard";

import store from "./store";

// import "assets/css/material-dashboard-react.css?v=1.9.0";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoginHistory from "../deepliquidity_exchange_server/modals/loginHistory";

import globalStyle from "./style/global.style";

// import action
import { decodeJwt } from "./actions/jsonWebToken";
import EditProfile from "./pages/AdminProfile/EditProfile";

import FaqList from "./pages/Faq/faqList";
import FaqAdd from "./pages/Faq/faqAdd";
import FaqUpdate from "./pages/Faq/faqUpdate";
import Withdraw from "./pages/withdraw/Withdraw";
import ViewWithdraw from "./pages/withdraw/ViewWithdraw";

import DirectReferalPercentage from "./pages/DirectReferalPercentage/DirectReferalPercentage";

import ReferalEarning from "./pages/ReferalEarning/ReferalEarning";
import kingReward from "./pages/settings/kingReward";
import QueenReward from "./pages/settings/QueenReward";
import Add from "./pages/settings/Add";
import Set from "./pages/settings/Set";
import TokenPrice from "./pages/settings/TokenPrice";
import UpdatePlan_Price from "./pages/settings/UpdatePlan_Price";
import kycVerify from "./pages/KYC/kycVerify";
import kycView from "./pages/KYC/KycView";
import Userdetails from "./pages/KYC/Userdetails";
import KingFeatures from "./pages/features/KingFeatures";
import QueenFeatures from "./pages/features/QueenFeatures";
import Staking_With from "./pages/StakingWithdraw/Staking_With";
import Staking_View from "./pages/StakingWithdraw/Staking_View";
import changepassword from "./pages/ChangePassword/Changepasswpord";
import Mining_View from "./pages/MiningWithdraw/Mining_view";
import Mining_with from "./pages/MiningWithdraw/Mining_with";
import PollingList from "./pages/settings/PoolingList";
import UserList from "./pages/user/UserList";
import UpdateStak from "./pages/settings/UpdateStak_sec";
import WithdrawLimit from "./pages/settings/Update_WithDraw_Limit";
import ReferalUser from "./pages/user/RerferalUser";
import stakingList from "./pages/Action-stak-mini/Stakinglist";
import MinigList from "./pages/Action-stak-mini/Mininglist";
import Adsplan from "./pages/AdsPlan-data/Ads-plan";
import MiningWithdrawOwner from "./pages/MiningWithdrawOwner/MiningWithdrawOwnerList";
import MiningOwner_View from "./pages/MiningWithdrawOwner/MiningOwner_View";
import StakeOwner from "./pages/StackWithdrawOwner/Stak_WiothdrawOwner";
import StakeOwner_View from "./pages/StackWithdrawOwner/stakWithdraw_View";
import busdWithdraw from "./pages/WithdrawGainBusd/WithdrawGainBusdlist";
import busdWithdraw_View from "./pages/WithdrawGainBusd/WithdrawBusd_View";
import Mine_view from "./pages/Action-stak-mini/MiningView";
import stak_view from "./pages/Action-stak-mini/StakingView";
// import referal from './pages/referal/Referal'
import nfcrequest from './pages/NfcRequest/NfcReq'

import cmsimage from './pages/Cms/CmsImg'


import AdminAdd from "./pages/Admin/AdminAdd";
import AdminEdit from "./pages/Admin/AdminEdit";
import AdminList from "./pages/Admin/AdminList";
import nfcreject from "./pages/NfcRequest/NfcReject"
import nfcview from './pages/NfcRequest/NfcView'

// import SwaggerList from "./components/Swagger/SwaggerList"ss

import { getStakeOwner } from "./actions/admin";

// import SwaggerList from "./components/Swagger/SwaggerList"

import NfcKingFeature from "./pages/NfcCardFeature/NfcKingFeature";
import NfcQueenFeature from "./pages/NfcCardFeature/NfcQueenFeature";
import UpdateNfcCardPrice from "./pages/settings/UpdateNfcCardPrice";
import NfcUserlist from "./pages/NFC_User/NfcUserlist";

import qbtvalue from "./pages/Qbtvalue/Qbtvalue"

import commission from "./pages/commission/NfcCommission"
import getcommissiomhiostory from './pages/commission/CommissionHistory'
import nfccardholder from './pages/NFC_User/Nfccarduser'
import sendqbt from './pages/manualqbt/Manualqbt'
import manualpay from './pages/sendqbt/sendqbt'
import CmsContent from './pages/Cms/Cmscontend'
import Verifyemail from "./pages/verifyposregister/Verifyemail";
import nfcTransaction from "./pages/NfcTransaction/NfcTransaction"
import busdtransction from "./pages/NfcTransaction/BusdTransaction"
import merchatcash from "./pages/merchantTransaction/merchantCashTran"
import merchatbusd from "./pages/merchantTransaction/merchantBusdTran"
import merchatdepositeandwithdraw from "./pages/depositeNdwithdraw/MerchantW&D"
import userdepositeandwithdraw from "./pages/depositeNdwithdraw/UserW&d";

const history = createBrowserHistory();

const App = () => {
  const { isAuth } = store.getState().currentUser;

  useEffect(() => {
    if (isAuth != true && localStorage.admin_token) {
      decodeJwt(localStorage.admin_token, store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={globalStyle}>
        <ToastContainer />
        {/* <Router history={history} basename={process.env.REACT_APP_BASENAME || "/ulosdewvhref"}> */}
        <Router history={history} basename={"/queenbotsAdmin"}>
          <Switch>
            {/* Auth Route */}
            <ConditionRoute path="/login" component={LoginPage} type={"auth"} />

            {/* Private Route */}
            <ConditionRoute
              path="/dashboard"
              component={Dashboard}
              layout={Admin}
              type={"private"}
              store={store}
            />

            {/* Product request */}

            {/* Ingredient request */}
            <ConditionRoute
              path="/Faq"
              component={FaqList}
              layout={Admin}
              type={"private"}
            />

            {/* EditProfile */}
            <ConditionRoute
              path="/editprofile"
              component={EditProfile}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/addFaq"
              component={FaqAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/faqUpdate/:faqId"
              component={FaqUpdate}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/withdraw"
              component={Withdraw}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/ViewWithdraw/:faqId"
              component={ViewWithdraw}
              layout={Admin}
              type={"public"}
            />

            <ConditionRoute
              path="/directreferal"
              component={DirectReferalPercentage}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/king-reward"
              component={kingReward}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/queen-reward"
              component={QueenReward}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/add"
              component={Add}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/set/:id"
              component={Set}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/referalearning"
              component={ReferalEarning}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/TokenPrice"
              component={TokenPrice}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/UpdatePlanPrice"
              component={UpdatePlan_Price}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/kyc"
              component={kycVerify}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/kycview/:kycid"
              component={kycView}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/userdetail/:userid"
              component={Userdetails}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/kingfeatures"
              component={KingFeatures}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/queenfeatures"
              component={QueenFeatures}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/stak-Withdraw"
              component={Staking_With}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/stak-view/:faqId"
              component={Staking_View}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/mining-Withdraw"
              component={Mining_with}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/mining-view/:faqId"
              component={Mining_View}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/changepassword"
              component={changepassword}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/poolinglist"
              component={PollingList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/userlist"
              component={UserList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/UpdateStack"
              component={UpdateStak}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/withdraw-limit"
              component={WithdrawLimit}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/Referal-user/:id"
              component={ReferalUser}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/staking-list"
              component={stakingList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/staking-View/:id"
              component={stak_view}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/mining-list"
              component={MinigList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/mine-view/:id"
              component={Mine_view}
              layout={Admin}
              type={"public"}
            />

            <ConditionRoute
              path="/ads-plan"
              component={Adsplan}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/mining-Owner"
              component={MiningWithdrawOwner}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/MiningOwner-View/:faqId"
              component={MiningOwner_View}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/StakeOwner-Withdraw"
              component={StakeOwner}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/StakeOwner-View/:faqId"
              component={StakeOwner_View}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/Busd-Withdraw"
              component={busdWithdraw}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/Busd-Withdraw-View/:faqId"
              component={busdWithdraw_View}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/list"
              component={AdminList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/eidt/:userId"
              component={AdminEdit}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/addadmin"
              component={AdminAdd}
              layout={Admin}
              type={"public"}
            />
            {/* 
            <ConditionRoute
              exact
              path="/referal"
              component={referal}
              layout={Admin}
              type={"public"}
            /> */}
            <ConditionRoute
              exact
              path="/cms"
              component={cmsimage}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/nfc-request"
              component={nfcrequest}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/nfc-card-list"
              component={nfcreject}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/view-nfc/:id"
              component={nfcview}
              layout={Admin}
              type={"public"}
            />

            {/* //Muvitha */}

            <ConditionRoute
              path="/NfcKingFeature"
              component={NfcKingFeature}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/NfcQueenFeature"
              component={NfcQueenFeature}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/UpdateNfcCardPrice"
              component={UpdateNfcCardPrice}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/NfcUserlist"
              component={NfcUserlist}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/qbt-value"
              component={qbtvalue}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/commission"
              component={commission}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/commission-history"
              component={getcommissiomhiostory}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/nfccard-user"
              component={nfccardholder}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/manualpay-qbt"
              component={manualpay}
              layout={Admin}
              type={"private"}
            />
            {/* <ConditionRoute
              exact
              path="/swaggerdoc"
              component={SwaggerList}

            /> */}
            <ConditionRoute
              path="/send-qbt"
              component={sendqbt}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/cms-Content"
              component={CmsContent}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/nfc-transaction"
              component={nfcTransaction}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/busd-transaction"
              component={busdtransction}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/merchant-cash-transaction"
              component={merchatcash}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/merchant-busd-transaction"
              component={merchatbusd}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/merchant-deposite-and-withdraw"
              component={merchatdepositeandwithdraw}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/user-deposite-and-withdraw"
              component={userdepositeandwithdraw}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/Verifyemail/:userId/:id"
              component={Verifyemail}
              // layout={Admin}
              type={"public"}
            />
            <Redirect from="/" to="/login" />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
