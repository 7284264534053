import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Tasks from "../../components/Tasks/Tasks.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import Danger from "../../components/Typography/Danger.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { toast } from "react-toastify";
import DashboardCount from '../../components/DashboardCount';
import Web3 from "web3";
import { bugs, website, server } from "../../variables/general.js";
import config from "../../actions/config.js";
import config1 from "../../../src/lib/config"
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "../../variables/charts.js";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {

  Getcountdata,
  getwithdrawList,
  StakandMiningTotal
} from "../../actions/admin.js";


import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { idcrtnetwork } from "../../lib/history"

const useStyles = makeStyles(styles);

toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};


export default function Dashboard() {
  const [account, setAccount] = useState();
  const [alluser, setalluser] = useState();
  const [todayuser, settodayuser] = useState();
  const [todayStack, settodayStack] = useState();
  const [todaymining, settodaymining] = useState();
  const [totalmining, settotalmining] = useState();
  const [totalstaking, settotalstaking] = useState();
  const [withdrawlist, setwithdrawlist] = useState([]);

  const [WithdrawMineUser, setWithdrawMineUser] = useState([])
  const [WithdrawStakeUser, setWithdrawStakeUser] = useState([])
  const [WithdrawMineOwner, setWithdrawMineOwner] = useState([])
  const [WithdrawStakeOwner, setWithdrawStakeOwner] = useState([])
  const [WithdrawReferal, setWithdrawReferal] = useState([])
  const [WithdrawGainBusd, setWithdrawGainBusd] = useState([])


  const [staking, setsatking] = useState();
  const [Mining, setminig] = useState();



  // console.log(Mining,staking, "datatatat");

  // console.log(WithdrawMineUser,
  //   WithdrawStakeUser,
  //   WithdrawMineOwner,
  //   WithdrawStakeOwner,
  //   WithdrawReferal,
  //   WithdrawGainBusd)


  // console.log(config.admin_address,"admin_address");

  const classes = useStyles();

  const web3 = new Web3(Web3.givenProvider);

  useEffect(() => {
    load()

  }, [])

  const load = async () => {

    const allusedata = await Getcountdata()
    const withdrawlist = await getwithdrawList()
    const SandMlist = await StakandMiningTotal()


    if (SandMlist) {
      setsatking(SandMlist.result.mining[0].totalAmount)
      setminig(SandMlist.result.staking[0].totalAmount)
    }



    if (withdrawlist) {
      setWithdrawMineUser(withdrawlist.result?.WithdrawMineUser[0])
      setWithdrawStakeUser(withdrawlist.result?.WithdrawStakeUser[0])
      setWithdrawMineOwner(withdrawlist.result?.WithdrawMineOwner[0])
      setWithdrawStakeOwner(withdrawlist.result?.WithdrawStakeOwner[0])
      setWithdrawReferal(withdrawlist.result?.WithdrawReferal[0])
      setWithdrawGainBusd(withdrawlist.result?.WithdrawGainBusd[0])
      setwithdrawlist(withdrawlist.result?.allwithdrawAMOUNT[0])

    }
    if (allusedata) {

      setalluser(allusedata.result.totalUsercount)
      settodayuser(allusedata.result.todayuser)
      settodayStack(allusedata.result.todayStack)
      settodaymining(allusedata.result.todayMining)
      settotalmining(allusedata.result.totalMining)
      settotalstaking(allusedata.result.totalstaking)
    }

    // console.log(allusedata);

    let accounts = await web3.eth.requestAccounts();
    // accounts = accounts[0];
    setAccount(accounts);
    // window.location.reload();
    if (accounts != config.admin_address) {
      toast.error("admin address is not match", toasterOption);
    }

    // let chaninid = await web3.eth.getChainId();
    // if (chaninid != config1.network) {
    //   toast.error(config1.message, toasterOption);
    //   return false
    // }
    idcrtnetwork()

  }



  return (
    <div>



      {/* <DashboardCount classes={classes} /> */}
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card
            // onClick={PushWith}
            style={{ paddingTop: "17px", paddingBottom: "15px" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger" style={{ marginTop: "0px" }}>
                <PeopleOutlineOutlinedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total User Count</p>
              <h3 className={classes.cardTitle}>{alluser ? alluser : 0}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card
            // onClick={PushWith}
            style={{ paddingTop: "17px", paddingBottom: "15px" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="success" style={{ marginTop: "0px" }}>
                <MonetizationOnOutlinedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Staking Count</p>
              <h3 className={classes.cardTitle}>{totalstaking ? totalstaking : 0}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card
            // onClick={PushWith}
            style={{ paddingTop: "17px", paddingBottom: "15px" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="primary" style={{ marginTop: "0px" }}>
                <AttachMoneyIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Mining Count</p>
              <h3 className={classes.cardTitle}>{totalmining ? totalmining : 0}</h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <br/> */}
      <br />
      <GridContainer>
        <GridItem xs={2} sm={1} md={12}>
          <Card>
            <CardIcon style={{ marginLeft: "16px", marginTop: "20px", marginBottom: "20px" }} color="info">
              <h4 className={classes.cardTitleWhite}>24hr Details</h4>
            </CardIcon>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card
            // onClick={PushWith}
            style={{ paddingTop: "17px", paddingBottom: "15px" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger" style={{ marginTop: "0px" }}>
                <PeopleOutlineOutlinedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Last 24hr  User Count</p>
              <h3 className={classes.cardTitle}>{todayuser ? todayuser : 0}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card
            // onClick={PushWith}
            style={{ paddingTop: "17px", paddingBottom: "15px" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="success" style={{ marginTop: "0px" }}>
                <MonetizationOnOutlinedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Last 24hr  Staking Count</p>
              <h3 className={classes.cardTitle}>{todayStack ? todayStack : 0}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card
            // onClick={PushWith}
            style={{ paddingTop: "17px", paddingBottom: "15px" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="primary" style={{ marginTop: "0px" }}>
                <AttachMoneyIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Last 24hr Mining Count</p>
              <h3 className={classes.cardTitle}>{todaymining ? todaymining : 0}</h3>
            </CardHeader>
          </Card>
        </GridItem>

      </GridContainer>
      <GridContainer>
        <GridItem xs={2} sm={1} md={12}>
          <Card>
            <CardIcon style={{ marginLeft: "16px", marginTop: "20px", marginBottom: "20px" }} color="info">
              <h4 className={classes.cardTitleWhite}>Staking and Mining Details</h4>
            </CardIcon>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card
            // onClick={PushWith}
            style={{ paddingTop: "17px", paddingBottom: "15px" }}
          >
            <CardHeader color="success" stats icon>
              <CardIcon color="success" style={{ marginTop: "0px" }}>
                <MonetizationOnOutlinedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Staking Amount</p>
              <h3 className={classes.cardTitle}>{staking ? (staking).toFixed(4) : 0}</h3>
              {/* <p className={classes.cardCategory}>Today Staking Amount</p>
              <h3 className={classes.cardTitle}>{staking ? (staking).toFixed(4) : 0}</h3> */}
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card
            // onClick={PushWith}
            style={{ paddingTop: "17px", paddingBottom: "15px" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="primary" style={{ marginTop: "0px" }}>
                <AttachMoneyIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Mining Amount</p>
              <h3 className={classes.cardTitle}>{Mining ? (Mining).toFixed(4) : 0}</h3>
              {/* <p className={classes.cardCategory}>Today Mining Amount</p>
              <h3 className={classes.cardTitle}>{Mining ? (Mining).toFixed(4) : 0}</h3> */}
            </CardHeader>
          </Card>
        </GridItem>

      </GridContainer>
      <GridContainer>
        <GridItem xs={2} sm={1} md={12}>
          <Card>
            <CardIcon style={{ marginLeft: "16px", marginTop: "20px", marginBottom: "20px" }} color="info">
              <h4 className={classes.cardTitleWhite}>WithDraw Details</h4>
            </CardIcon>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>

        <GridItem xs={12} sm={5} md={2}>
          <h4>Total Transaction</h4>
          <Card
            // onClick={PushWithPlans}
            style={{
              paddingTop: "55px",
              paddingBottom: "55px",
              background: "orange",
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "40px",
                borderBottom: "1px solid #ffffff",
                paddingBottom: "8px",
                fontWeight: "600",
              }}
              className={classes.cardCategory}
            >
              {withdrawlist == undefined ? "0" : withdrawlist.count}
            </p>
            <h6
              style={{
                textAlign: "center",
                color: "#ffffff",
                paddingTop: "10px",
                fontSize: "16px",
                fontSize: "17px",

              }}
              className={classes.cardTitle}
            >
              {withdrawlist == undefined ? "0" : Number(withdrawlist.totalAmount).toFixed(2)}
              {/* <p>Busd</p> */}
            </h6>
          </Card>
        </GridItem>
        <GridItem xs={4} sm={3} md={1} style={{ marginRight: '50px' }}>
          <h4>Mining withdraw</h4>
          <Card
            // onClick={PushWithPlans}
            style={{
              paddingTop: "45px",
              paddingBottom: "45px",
              background: "rgb(94 169 207)",
              width: '116px'
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "40px",
                borderBottom: "1px solid #ffffff",
                paddingBottom: "8px",
                fontWeight: "600",

              }}
              className={classes.cardCategory}
            >
              {WithdrawMineUser == undefined ? "0" : WithdrawMineUser.count}
            </p>
            <h6
              style={{
                textAlign: "center",
                color: "#ffffff",
                paddingTop: "10px",
                fontSize: "16px",
                fontSize: "17px",
              }}
              className={classes.cardTitle}
            >
              {WithdrawMineUser == undefined ? "0" : Number(WithdrawMineUser.totalAmount).toFixed(4)}
              {/* <p>Busd</p> */}
            </h6>

          </Card>
        </GridItem>
        <GridItem xs={4} sm={5} md={1} style={{ marginRight: '50px' }}>
          <h4>Staking withdraw</h4>
          <Card
            // onClick={PushWithPlans}
            style={{
              paddingTop: "45px",
              paddingBottom: "45px",
              background: "rgb(94 169 207)",
              width: '116px'
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "40px",
                borderBottom: "1px solid #ffffff",
                paddingBottom: "8px",
                fontWeight: "600",

              }}
              className={classes.cardCategory}
            >
              {WithdrawStakeUser == undefined ? "0" : WithdrawStakeUser.count}
            </p>
            <h6
              style={{
                textAlign: "center",
                color: "#ffffff",
                paddingTop: "10px",
                fontSize: "16px",
                fontSize: "17px",
              }}
              className={classes.cardTitle}
            >
              {WithdrawStakeUser == undefined ? "0" : Number(WithdrawStakeUser.totalAmount).toFixed(4)}
              {/* <p>Busd</p> */}
            </h6>

          </Card>
        </GridItem>
        <GridItem xs={4} sm={5} md={1} style={{ marginRight: '50px' }}>
          <h4>Company Mining</h4>
          <Card
            // onClick={PushWithPlans}
            style={{
              paddingTop: "45px",
              paddingBottom: "45px",
              background: "rgb(94 169 207)",
              width: '116px'
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "40px",
                borderBottom: "1px solid #ffffff",
                paddingBottom: "8px",
                fontWeight: "600",

              }}
              className={classes.cardCategory}
            >
              {WithdrawMineOwner == undefined ? "0" : WithdrawMineOwner.count}
            </p>
            <h6
              style={{
                textAlign: "center",
                color: "#ffffff",
                paddingTop: "10px",
                fontSize: "16px",
                fontSize: "17px",
              }}
              className={classes.cardTitle}
            >
              {WithdrawMineOwner == undefined ? "0" : Number(WithdrawMineOwner.totalAmount).toFixed(4)}
              {/* <p>Busd</p> */}
            </h6>

          </Card>
        </GridItem>
        <GridItem xs={4} sm={5} md={1} style={{ marginRight: '50px' }}>
          <h4> Company Staking</h4>
          <Card
            // onClick={PushWithPlans}
            style={{
              paddingTop: "45px",
              paddingBottom: "45px",
              background: "rgb(94 169 207)",
              width: '116px'
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "40px",
                borderBottom: "1px solid #ffffff",
                paddingBottom: "8px",
                fontWeight: "600",

              }}
              className={classes.cardCategory}
            >
              {WithdrawStakeOwner == undefined ? "0" : WithdrawStakeOwner.count}
            </p>
            <h6
              style={{
                textAlign: "center",
                color: "#ffffff",
                paddingTop: "10px",
                fontSize: "16px",
                fontSize: "17px",
              }}
              className={classes.cardTitle}
            >
              {WithdrawStakeOwner == undefined ? "0" : Number(WithdrawStakeOwner.totalAmount).toFixed(4)}
              {/* <p>Busd</p> */}
            </h6>

          </Card>
        </GridItem>
        <GridItem xs={4} sm={5} md={1} style={{ marginRight: '50px' }}>
          <h4>M & S reward</h4>
          <Card
            // onClick={}
            style={{
              paddingTop: "45px",
              paddingBottom: "45px",
              background: "rgb(94 169 207)",
              width: '116px'
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "40px",
                borderBottom: "1px solid #ffffff",
                paddingBottom: "8px",
                fontWeight: "600",

              }}
              className={classes.cardCategory}
            >
              {WithdrawReferal == undefined ? "0" : WithdrawReferal.count}
            </p>
            <h6
              style={{
                textAlign: "center",
                color: "#ffffff",
                paddingTop: "10px",
                fontSize: "16px",
                fontSize: "17px",
              }}
              className={classes.cardTitle}
            >
              {WithdrawReferal == undefined ? "0" : Number(WithdrawReferal.totalAmount).toFixed(4)}
              {/* <p>Busd</p> */}
            </h6>

          </Card>
        </GridItem>
        <GridItem xs={4} sm={5} md={1} style={{ marginRight: '50px' }}>
          <h4>M & S Busd</h4>
          <Card
            // onClick={}
            style={{
              paddingTop: "45px",
              paddingBottom: "45px",
              background: "rgb(94 169 207)",
              width: '116px'
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "40px",
                borderBottom: "1px solid #ffffff",
                paddingBottom: "8px",
                fontWeight: "600",
              }}
              className={classes.cardCategory}
            >
              {WithdrawGainBusd == undefined ? "0" : WithdrawGainBusd.count}
            </p>
            <h6
              style={{
                textAlign: "center",
                color: "#ffffff",
                paddingTop: "10px",
                fontSize: "16px",
                fontSize: "17px",
              }}
              className={classes.cardTitle}
            >
              {WithdrawGainBusd == undefined ? "0" : Number(WithdrawGainBusd.totalAmount).toFixed(4)}
              {/* <p>Busd</p> */}
            </h6>

          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
