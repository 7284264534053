import axios from "axios";
// import action
import { decodeJwt } from "./jsonWebToken";

// import lib
import config from "../lib/config";
import setAuthToken from "../lib/setAuthToken";

import { SET_CURRENT_USER } from "../constant";

//LoginAction

// axios.defaults.baseURL = config.baseUrl;
// axios.defaults.headers.common['authorization'] = localStorage.getItem("admin_token")

export const login = async (data, dispatch) => {
  try {
    // // console.log(data,'dataa')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/login`,
      data,
    });

    localStorage.setItem("admin_token", respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

//AdminProfile
export const getadminDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/adminDetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updateProfile = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateAdminProfile`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getFaqId = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getFaq/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const updateFaq = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const logout = (history) => {
  localStorage.removeItem("admin_token");
  history.push("/Login");
  setAuthToken("");
};
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
//faq
export const getFaqList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getFaqList`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const deleteFaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/deleteFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const addFaq = async (data) => {
  try {
    // console.log(data, "checking data");
    // console.log(config.API_URL, "url");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/addFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
// withdraw-------------------------------------------------
export const withdraw = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/withdraw`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getWithdrawData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getwithdraw`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getSingleWithdraw = async (id) => {
  // console.log(id, "iddddddddd");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getSingleWithdrawList/${id}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getstaking = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getstaking`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getStakingWithdrawList = async (id) => {
  // console.log(id, "iddddddddd");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getStakingWithdrawList/${id}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getminingwith = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getminingwith`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getMiningWithdrawList = async (id) => {
  // console.log(id, "iddddddddd");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getMiningWithdrawList/${id}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getminingwithOwner = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getminingwithOwner`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getMiningWithdrawOwnert = async (id) => {
  // console.log(id, "iddddddddd");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getMiningWithdrawOwnert/${id}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getStakeOwner = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getStakeOwner`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getWithdrawStakeOwner = async (id) => {
  // console.log(id, "iddddddddd");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getWithdrawStakeOwner/${id}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getWithdrawGainBusd = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getWithdrawGainBusd`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getWithdrawGainbusdSingle = async (id) => {
  // console.log(id, "iddddddddd");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getWithdrawGainbusdSingle/${id}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

// settings-------------------------------------------------------
export const getReferalPercentage = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/ReferalPercentageDetail`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updateReferalPercentage = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateReferalPercentage`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getReferalEarningdetail = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/ReferalRewardDetail`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updateReferalearning = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updatereferalReward`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getkingReward = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/KingRewardDetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updatekingReward = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateKingReward`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getQueenReward = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/QueenRewardDetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updateQueenReward = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateQueenReward`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getadd = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/add`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updateadd = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateadd`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getsetdata = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/Setdetails/${data}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updatesetdata = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateSetdetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getwithdrawLimit = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getwithdrawLimit`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updatewithdrawLimit = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updatewithdrawLimit`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getwithdrawStaking = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getwithdrawStaking`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const updatewithdrawStaking = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updatewithdrawStaking`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
// kyc----------------------------------------------------------
export const getKycDetails = async (data) => {
  console.log(data, "data");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/kycDetail`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getkyclistone = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/kyclistone/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const setapproveKyc = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/approveKyc`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const setrejectKyc = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/rejectKyc`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getKycHistory = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/history/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getKingfeaturs = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getkingfeatures`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const setfeaturesSSS = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/setfeature`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const deletefeature = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/deletefeature`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const addfeatures = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/KingFeature`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const Getcountdata = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/todayuser`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const changepassword = async (data, dispatch) => {
  try {
    console.log(data, "dataa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/changePassward`,
      data,
    });
    //
    // localStorage.setItem("admin_token", respData.data.token);
    // setAuthToken(respData.data.token);
    // decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const checkOtp = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/checkOpt`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getmaxuser = async (data) => {
  try {
    console.log(data, "AXios Dataaaaaaaaaaaaaaaaaaaa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/maxuser`,
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getplandata = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesUser/getplandata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const postPlandata = async (data) => {
  try {
    console.log(data, "AXios Dataaaaaaaaaaaaaaaaaaaa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/addplandata`,
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const poststakingdata = async (data) => {
  try {
    console.log(data, "AXios Dataaaaaaaaaaaaaaaaaaaa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/stakinglimitdata`,
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getstaking_seconds = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getStakingLimit`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const withdrawlimitdata = async (data) => {
  try {
    console.log(data, "AXios Dataaaaaaaaaaaaaaaaaaaa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/withdrawlimitdata`,
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getwithdrawdata = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getwithdrawdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getreferalByaddress = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/getreferal/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getminingdata = async (data) => {
  console.log(data, "hjjjghjhghjghjh");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getMiningDetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getstackingdata = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getstackingDetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const ads_settings = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/adsSettings`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const deleteAds = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/deleteAds`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getadsData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getadsData`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getwithdrawList = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/withdrawList`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const StakandMiningTotal = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/StakandMiningTotal`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err?.response.data.status,
      message: err?.response.data.message,
      error: err?.response.data.error,
    };
  }
};

export const getStakandmining = async (id) => {
  console.log(id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/getStakandmining/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getSingleAdmin = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/singleAdmin/${id}`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const editAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.baseUrl}/adminapi/admin`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getAllAdmin = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/admin`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const deleteAdmin = async (id) => {
  console.log(id, "IDDDDDDDDDDDDDDD");
  try {
    let respData = await axios({
      method: "delete",
      url: `${config.baseUrl}/adminapi/deleteAdmin/${id}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const addNewAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/admin`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const cmsbannerUpload = async (data) => {
  console.log(data.image, "llll");
  const formData = new FormData();

  formData.append("banner_img", data.image);

  // console.log(formData);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/cmsbannerUpload`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: formData,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getcmsimg = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getcmsimg`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const deletecms = async (data) => {
  // console.log(formData);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/deleteCMS`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

//muvitha

export const getNfcfeaturs = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getNfcfeaturs`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const addNfcfeature = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/addNfcfeature`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updateNfcFeature = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateNfcFeature`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const deleteNfcfeature = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/deleteNfcfeature`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updateNfcCardprice = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateNfcCardprice`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getNfcCardprice = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getNfcCardprice`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getNfcUsers = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getNfcUsers`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const requestdata = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/requestdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};




export const NFCAppandRej = async (data) => {

  // console.log(formData);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/NFCAppandRej`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};



export const rejectlist = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/rejectlist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};




export const xlsetdata = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/xlsetdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};



export const gettokenvalue = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/gettokenvalue`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const tokenvalueAed = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/token-price`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getcommissiondata = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/get-commision`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updatecommission = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/update-commission`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};


export const commissionHistory = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/update-commissionHistory`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};




export const getcommmissionhistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/get-commmission-history`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const nfcCarduser = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/get-userwith-card`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const sendabtmanual = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/sendabtmanual`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};


export const getrequestQbt = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/get-request-Qbt
      `,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const approvereq = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/approve-req`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};



export const rejectmanual = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/reject-manual`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const cmscontentupdate = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/cmscontentupdate`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getcmscontentdata = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getcmscontentdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};




export const verifyemail = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/verify-email`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};




export const nfcTransactionHistory = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/nfc-Transaction-details`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};


export const BusdnfcTransactiondetails = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/Busd-nfc-Transactiondetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const merchantTransactiondetails = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/merchant-Transaction-details`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};



export const BusdmerchantTransactiondetails = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/Busd-merchant-Transaction-details`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};


export const MerchantDepositNdWithdraw = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/Merchant-Deposit-Nd-Withdraw`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};


export const UserDepositNdWithdraw = async (data) => {
  console.log(data, "kkk");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/User-Deposit-Nd-Withdraw`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};