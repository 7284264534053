import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";

import 'react-phone-input-2/lib/style.css'

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import { cmsbannerUpload, getcmsimg, deletecms } from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";
import config from "../../lib/config"
import config2 from "../../actions/config"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Web3 from "web3";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none"
    }
};

// toaster config
toast.configure();



let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
    const customStyles = customStyle();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();


    const [img, setimg] = useState("")
    const [file, setFile] = useState("")
    const [allimg, setalldata] = useState()
    const [singleimg, setsingleimg] = useState()
    const [iddata, setiddata] = useState()
    const [OpenSelfie, setOpenSelfie] = useState(false);
    const [Account, setAccount] = useState()

    console.log(img, "hai da thambi");

    useEffect(() => {
        acc();
    }, []);

    const acc = async () => {
        const web3 = new Web3(window.ethereum);
        let accounts = await web3.eth.requestAccounts();
        setAccount(accounts);
    };


    const selectFileChange = (e) => {

        if (Account != config2.admin_address) {
            toast.error("connect your valid account", toasterOption);
            return false;
        }

        var file = e.target.files[0];
        setimg(file)
        setFile(URL.createObjectURL(e.target.files[0]));
    }





    const handleFormSubmit = async (e) => {

        try {

            if (Account != config2.admin_address) {
                toast.error("connect your valid account", toasterOption);
                return false;
            }

            const reqdata = {
                image: img
            }

            const result = await cmsbannerUpload(reqdata)
            console.log(result, "vanthuruchu");
            if (result) {
                toast.success("Upload Successfully", toasterOption);
                window.location.reload()
            }



        } catch (err) {

        }



    }



    useEffect(() => {
        getadmindata()
    }, [])

    const getadmindata = async () => {
        var { result } = await getcmsimg();
        console.log(result, "lll");
        if (result) {
            setalldata(result)
        }


    }



    const handleselfieClose = (e) => {
        e.preventDefault();
        setOpenSelfie(false);
    };


    const handleAddressopen = (imagedata, id) => {

        if (Account != config2.admin_address) {
            toast.error("connect your valid account", toasterOption);
            return false;
        }
        setOpenSelfie(true)
        setsingleimg(imagedata)
        setiddata(id)

    }



    const Deletedata = async (id) => {
        const reqdata = {
            id: id

        }
        const deletedata = await deletecms(reqdata);

        if (deletedata) {
            window.location.reload()

        }

    }


    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        {/* <form className={classes.form} noValidate onSubmit={handleFormSubmit}> */}
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>CMS Banner Update</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>

                                    <Button
                                        style={{
                                            width: "20%",
                                            marginTop: "20px",
                                        }}
                                        variant="contained"
                                        component="label"
                                        color="rose"
                                    >
                                        Select Banner
                                        <input
                                            type="file"
                                            id="image"
                                            name="avimageatar"
                                            onChange={selectFileChange}
                                            hidden
                                        />
                                    </Button>
                                    <p>(only 1024 X 500 px)</p>
                                </GridItem>

                                {file != "" ?
                                    <GridItem xs={12} sm={12} md={6}>
                                        {/* <img src={file} /> */}
                                        <img
                                            src={
                                                file
                                            }
                                            id="get_file_2"
                                            class="lazy nft__item_preview"
                                            alt=""
                                            style={{ width: "150px", height: "150px" }}
                                        />
                                        <p>Make Sure uploaded image must be (1024 X 500 px) else it will affect app Banners</p>

                                    </GridItem>
                                    : " "}
                                <br />

                            </GridContainer>
                            <GridContainer>
                                {file != "" ?
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Button color="primary" size="sm" onClick={handleFormSubmit}>Update</Button>
                                    </GridItem>
                                    : " "}
                            </GridContainer>


                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <ImageList sx={{ width: "100%", height: "100%" }}>
                                        <ImageListItem key="Subheader" cols={2}>
                                            <ListSubheader component="div">Banner Images</ListSubheader>
                                        </ImageListItem>
                                        {allimg?.map((item) => (
                                            <ImageListItem key={item.Image}>
                                                <img
                                                    src={`${config.baseUrl}/images/${item.Image}`}
                                                    // srcSet={`${item.img}?`}
                                                    // alt={item.title}
                                                    loading="lazy"
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                                <ImageListItemBar
                                                    title={item.title}
                                                    subtitle={item.author}
                                                    actionIcon={
                                                        <IconButton
                                                            onClick={() => handleAddressopen(item.Image, item._id)}
                                                            sx={{ color: 'white' }}
                                                            aria-label={`info about ${item.title}`}
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        {/* <CardFooter>
                                <Button color="primary" type="submit">back</Button>
                            </CardFooter> */}
                        {/* </form> */}
                    </Card>
                </GridItem>
            </GridContainer>
            <div>
                <Dialog
                    open={OpenSelfie}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleselfieClose}
                    // aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Banner"}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <label for="exampleInputEmail1">Banner </label>
                            <Button
                                color="rose"
                                size="sm"
                                href={`${config.baseUrl}/images/${singleimg}`}
                                target="_blank"
                            >
                                View
                            </Button>
                            <img
                                src={`${config.baseUrl}/images/${singleimg}`}
                                alt="id proof"
                                style={{ width: "100%", height: "100%" }}
                            />


                            <DialogActions>
                                {/* <Button
                                    // style={{
                                    //     width: "20%",
                                    //     marginTop: "20px",
                                    // }}
                                    variant="contained"
                                    component="label"
                                    color="success"
                                >
                                    Edit
                                    <input
                                        type="file"
                                        id="image"
                                        name="avimageatar"
                                        // onChange={selectFileChange}
                                        hidden
                                    />
                                </Button> */}

                                <Button
                                    color="danger"
                                    onClick={() => Deletedata(iddata)}
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleselfieClose} color="primary"
                        >
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}
