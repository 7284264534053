import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getadd, Lockplandata } from "../../actions/admin";
import Search from "@material-ui/icons/Search";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Link, useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { toastAlert } from "../../lib/toastAlert";
import CardHeader from "../../components/Card/CardHeader.js";
import * as moment from "moment";
import Web3 from "web3";
import ReactDatatable from "@ashvin27/react-datatable";
import config from "../../actions/config";
// function createData(FirstName, lastName, Email, phoneNo, accountType) {
//   return { FirstName, lastName, Email, phoneNo, accountType };
// }
import { toast } from 'react-toastify';
const rows = [
    // getData('')
    //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
    //   createData('Donut', 452, 25.0, 51, 4.9),
];

const headCells = [
    { id: "category", numeric: false, disablePadding: true, label: "Token" },
    { id: "bidaddress", numeric: true, disablePadding: false, label: "Date" },
    { id: "tokendate", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "center" : "center"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 76%",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        ></Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onChangeterm: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};
const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const customStyles = customStyle();
    const history = useHistory();
    const classesSearch = useToolbarStyles();
    const [data, setData] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userDetails, setUser] = useState([]);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [responsive, setresponsive] = useState(true);

    async function editR(id) {
        const web3 = new Web3(window.ethereum);
        let accounts = await web3.eth.requestAccounts();



        if (accounts != config.admin_address) {


            toast.error("connect your valid account", toasterOption);
            return false;
        }



        if (id != "") {
            history.push("/set/" + id);
        }

    }


    function lock() { }
    const handleChange = (event) => {
        const { name, value } = event.target;
        const self = this;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setSearchQuery(value);
        setTypingTimeout(
            setTimeout(function () {
                getAll({ search: value });
            }, 1000)
        );
    };

    const handleRequestSort = (event, property) => { };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    useEffect(() => {
        getAll();
    }, []);
    const getAll = async (search = {}) => {
        let filterData = search;
        if (filterData.search == "") {
            filterData = {};
        }
        var test = await getadd();
        // getplanDetails(filterData);
        // gettokenlist(filterData);
        if (test && test.result) {
            console.log("tokenlistt", test.result);
            setUser(test.result);
        }
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };


    const handladdGo = () => history.push('/add');


    const isSelected = (name) => selected.indexOf(name) !== -1;
    const columns = [
        {
            key: "Name",
            text: "Name",
            className: "Name",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center"> {record.name}</p>
                </div>
            ),
        }, {
            key: "planNaRewardPercentageme",
            text: "Reward Percentage",
            className: "planNaRewardPercentageme",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center"> {record.rewardPercentage}</p>
                </div>
            ),
        },
        {
            key: "Duration",
            text: "Duration",
            className: "Duration",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{record.duration}</p>
                </div>
            ),
        }, {
            key: "MininWStack",
            text: "Minimum Stake Amount",
            className: "planAmount",
            align: "center",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{record.minStakeAmount}</p>
                </div>
            ),
        }, {
            key: "Maxmimstack",
            text: "Maximum Stake Amount",
            className: "planAmount",
            align: "center",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{record.maxStakeAmount}</p>
                </div>
            ),
        },
        {
            key: "Staking",
            text: "Staking",
            className: "Staking",
            align: "left",
            sortable: false,
            cell: record =>
                <div>{record.isActve == true ? <span class="badge badge-pill badge-success">Active</span> : <span class="badge badge-pill badge-danger">InActive</span>}</div>
        }, {
            key: "Mining",
            text: "Mining",
            className: "Mining",
            align: "left",
            sortable: false,
            cell: record =>
                <div>{record.isMine == true ? <span class="badge badge-pill badge-success">Active</span> : <span class="badge badge-pill badge-danger">InActive</span>}</div>
        },


        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: (record) => {


                return (
                    <Fragment>
                        <button
                            data-toggle="modal"
                            data-target="#update-template-modal"
                            className="btn btn-primary btn-sm"
                            onClick={() => editR(record._id)}
                            style={{ marginRight: "5px" }}
                        >
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </button>



                    </Fragment>
                );
            },
        },
    ];

    const configdata = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: "No user found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };
    function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar
                    className={clsx(classesSearch.root, {
                        [classesSearch.highlight]: selected.length > 0,
                    })}
                >
                    <Typography
                        className={classesSearch.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Pooling List
                    </Typography>
                    {/* <Typography className={classesSearch.title} variant="h6" id="tableTitle" component="div">
                        King Reward </Typography> */}
                    <Button size="sm" color="rose" onClick={handladdGo}
                    >Add</Button>
                </Toolbar>
                <ReactDatatable
                    responsive={responsive}
                    config={configdata}
                    records={userDetails}
                    columns={columns}
                    onPageChange={pageChange()}
                />
            </Paper>
        </div>
    );
}
