module.exports = {

    baseUrl: "https://journeyapi.wealwin.com",  //demo

    singlesmartContract: "0xd1576F8A6895E17Ae4525061FDF6F430D3AfA3B8",

    network: "56",

    frontUrl: "https://journey.wealwin.com/",  //demo

    API_URL: "https://dexbotapi.alwin.io",  //demo

    //admin_address: "0x757Bd99408FbD217FbdF48E25b095060A6448b76",/// live user address
     admin_address: "0x26F9F0c809779feA862B28a106Fd883fac4a3Fb7",///test user  address

    // contract_address: "0x7b415C7DFCEdFbe29dfdae2034bd33D42581dE54",//live address

    contract_address: "0x391e2dAFc7240a77519BE6a56c2bDBd2f4DA6729",//test contract address


    //  bsc_scan:"https://testnet.bscscan.com/tx/",

    //   main_bsc_scan:""
}


// 0x26f9f0c809779fea862b28a106fd883fac4a3fb7