import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  getKycDetails,
  setapproveKyc,
  setrejectKyc,
  getKycHistory,
} from "../../actions/admin";
import Search from "@material-ui/icons/Search";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Link, useHistory, useParams } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { toastAlert } from "../../lib/toastAlert";
import CardHeader from "../../components/Card/CardHeader.js";
import * as moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
// import config2 from "../../lib/config";
// function createData(FirstName, lastName, Email, phoneNo, accountType) {
//   return { FirstName, lastName, Email, phoneNo, accountType };
// }
import Web3 from "web3";
import config from "../../actions/config"
import config2 from "../../lib/config";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const headCells = [
  { id: "category", numeric: false, disablePadding: true, label: "Token" },
  { id: "bidaddress", numeric: true, disablePadding: false, label: "Date" },
  { id: "tokendate", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 76%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
  const classes = useStyles();
  const customStyles = customStyle();
  const history = useHistory();
  const classesSearch = useToolbarStyles();
  const [data, setData] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userDetails, setUser] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [responsive, setresponsive] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openselfie, setOpenselfie] = useState(false);
  const [imagedata, setimagedata] = useState();
  const [backsideimg, setbacksideimg] = useState();
  const [IdName, setIdName] = useState()
  const [Type, setType] = useState();
  const [ProofNumber, setProofNumber] = useState();
  const [iddata, setiddata] = useState();
  const [Selfieiddata, setSelfieiddata] = useState();
  const [selfiemessage, setselfiemessage] = useState("");
  const [idreason, setidreason] = useState("");
  const [idproofreason, setidProofReason] = useState();
  const [selfieOpen, setselfieOpen] = useState(false);
  const { kycid } = useParams();



  const [openPan, setopenpan] = useState(false)
  const [panfront, setpanfront] = useState();
  const [panback, setpanback] = useState();
  const [panName, setpanName] = useState();
  const [panNumber, setpanNumber] = useState();
  const [panreason, setpanreason] = useState("")


  const [OpenAddress, setOpenAddress] = useState(false)
  const [Addressfront, setAddressfront] = useState();
  const [Addressback, setAddressback] = useState();
  const [Addresstype, setAddresstype] = useState();
  const [AddressName, setAddressName] = useState();
  const [AddressNumber, setAddressNumber] = useState();

  const [Addressreason, setAddressreason] = useState()

  // alert(Addressfront)

  const [Account, setAccount] = useState()

  useEffect(() => {
    acc();
  }, []);

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    setAccount(accounts);
  };


  const handleAddressopen = (addressfront, addressback, addressName, type, addressNumber, addressreason) => {

    if (Account != config.admin_address) {
      toast.error("connect your valid account", toasterOption);
      return false;
    }

    setOpenAddress(true)
    setAddressfront(addressfront)
    setAddressback(addressback)
    setAddressName(addressName)
    setAddressNumber(addressNumber)

    setAddresstype(type)
    setAddressreason(addressreason)
  }



  const handleopenPan = (panfront, panback, panName, panNumber, panreason) => {

    if (Account != config.admin_address) {
      toast.error("connect your valid account", toasterOption);
      return false;
    }

    setopenpan(true)
    setpanfront(panfront)
    setpanback(panback)
    setpanName(panName)
    setpanNumber(panNumber)
    setpanreason(panreason)

  }



  const handleClickOpen = (front, back, type, proofnumber, idreason, idName) => {

    if (Account != config.admin_address) {
      toast.error("connect your valid account", toasterOption);
      return false;
    }

    setOpen(true);
    setimagedata(front);
    setbacksideimg(back);
    setType(type);
    setProofNumber(proofnumber);
    setidreason(idreason);
    setIdName(idName)
  };

  const handleClickSelfie = (selfie, reason) => {

    if (Account != config.admin_address) {
      toast.error("connect your valid account", toasterOption);
      return false;
    }

    setselfieOpen(true);
    setOpenselfie(selfie);
    setselfiemessage(reason);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    setopenpan(false)
    setOpenAddress(false)
  };

  const handleselfieClose = (e) => {
    e.preventDefault();
    setselfieOpen(false);

  };

  useEffect(() => {
    getAll();
  }, []);
  const getAll = async (search = {}) => {
    let filterData = search;
    if (filterData.search == "") {
      filterData = {};
    }
    var test = await getKycHistory(kycid);
    // getplanDetails(filterData);
    // gettokenlist(filterData);
    if (test && test.result) {
      // console.log("tokenlistt", test.result);
      setUser(test.result);
    }
  };


  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const columns = [
    {
      key: "Date",
      text: "Date",
      fontfamily: "sans-serif",
      className: "Date",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          <p> {moment(record.updatedAt).format("MMMM,Do YYYY, hh:mm A")}</p>
        </div>
      ),
    },
    //  {
    //   key: "pan",
    //   text: "Pan-Document",
    //   className: "pan",
    //   align: "left",
    //   sortable: false,
    //   cell: (record) => (
    //     <div>
    //       {record.type == "pan" ? (
    //         <div>
    //           <Button
    //             color="rose"
    //             size="sm"
    //             onClick={() =>
    //               handleopenPan(
    //                 record.pan_frontImage,
    //                 record.pan_backImage,
    //                 record.pan_name,
    //                 record.pan_number,
    //                 record.pan_reason
    //               )
    //             }
    //           >Pan Details
    //           </Button>

    //           <p><div>{record.pan_status == "Verified" ? <span class="badge badge-pill badge-success">Verified</span> : record.pan_status == "Rejected" ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-warning">New</span>}</div></p>
    //         </div>
    //       ) : (
    //         "-"
    //       )}
    //     </div>
    //   ),
    // },
    {
      key: "Idproof",
      text: "Identity Document",
      className: "Idproof",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          {record.type == "idproof" ? (
            <div>
              <Button
                color="rose"
                size="sm"
                onClick={() =>
                  handleClickOpen(
                    record.idproof_frontImage,
                    record.idproof_backImage,
                    record.idproof_type,
                    record.idproof_proofNumber,
                    record.idproof_reason,
                    record.idproof_Name
                  )
                }
              >
                Id Proof
              </Button>
              <p><div>{record.idproof_status == "Verified" ? <span class="badge badge-pill badge-success">Verified</span> : record.idproof_status == "Rejected" ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-warning">New</span>}</div></p>
            </div>
          ) : (
            "-"
          )}
        </div>
      ),
    },

    {
      key: "selfe",
      text: "Selfie With Id-Proof",
      className: "selfie",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          {record.type == "selfie" ? (
            <div>
              <Button
                color="rose"
                size="sm"
                onClick={() =>
                  handleClickSelfie(
                    record.selfie_frontImage,
                    record.selfie_reason
                  )
                }
              >
                selfie
              </Button>
              <p><div>{record.selfie_status == "Verified" ? <span class="badge badge-pill badge-success">Verified</span> : record.selfie_status == "Rejected" ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-warning">New</span>}</div></p>
            </div>
          ) : (
            "-"
          )}
        </div>
      ),
    }, {
      key: "Residentialproof",
      text: "Proof Of Address",
      className: "Residentialproof",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          {record.type == "address" ? (
            <div>
              <Button
                color="rose"
                size="sm"
                onClick={() =>
                  handleAddressopen(
                    record.Address_frontImage, record.Address_backImage, record.Address_Name, record.Address_type, record.Address_proofNumber, record.Address_reason
                  )
                }
              >
                Address
              </Button>
              <p><div>{record.Address_status == "Verified" ? <span class="badge badge-pill badge-success">Verified</span> : record.Address_status == "Rejected" ? <span class="badge badge-pill badge-danger">Rejected</span> : <span class="badge badge-pill badge-warning">New</span>}</div></p>
            </div>
          ) : (
            "-"
          )}
        </div>
      ),
    },
  ];

  const configdata = {
    page_size: 10,
    length_menu: [5, 10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: false,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
  };
  function pageChange(pageData) {
    // console.log("OnPageChange", pageData);
  }
  return (
    <div>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar
            className={clsx(classesSearch.root, {
              [classesSearch.highlight]: selected.length > 0,
            })}
          >
            <Typography
              className={classesSearch.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              KYC History
            </Typography>
          </Toolbar>
          <ReactDatatable
            responsive={responsive}
            config={configdata}
            records={userDetails}
            columns={columns}
            onPageChange={pageChange()}
          />
        </Paper>
      </div>
      <div>
        <Dialog
          open={openPan}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">{" Pan Document "}</DialogTitle>

          {/* <DialogContent>
        <h3>Type :  surya</h3>
        </DialogContent> */}
          <DialogContent>
            <div>
              <div class="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={panName}
                  readOnly
                />
              </div>{" "}
              <div class="form-group">
                <label for="exampleInputEmail1">Pan Number</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={panNumber}
                  readOnly
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Front image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${panfront}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${panfront}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Back Side Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${panback}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${panback}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Reason</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  // onChange={handleidproof}
                  value={panreason}
                  readOnly
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>


      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">{" id proof "}</DialogTitle>

          {/* <DialogContent>
        <h3>Type :  surya</h3>
        </DialogContent> */}
          <DialogContent>
            <div>
              <div class="form-group">
                <label for="exampleInputEmail1">Type</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={Type}
                  readOnly
                />
              </div>{" "} <div class="form-group">
                <label for="exampleInputEmail1">{Type} Type</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={IdName}
                  readOnly
                />
              </div>{" "}
              <div class="form-group">
                <label for="exampleInputEmail1">{Type} Number</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={ProofNumber}
                  readOnly
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Front image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${imagedata}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${imagedata}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Back Side Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${backsideimg}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${backsideimg}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Reason</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  // onChange={handleidproof}
                  value={idreason}
                  readOnly
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>


      <div>
        <Dialog
          open={selfieOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleselfieClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Selfie Proof"}</DialogTitle>

          {/* <DialogContent>
        <h3>Type :  surya</h3>
        </DialogContent> */}

          <DialogContent>
            <div>
              <label for="exampleInputEmail1">View Selfie Image</label>
              <Button
                color="rose"
                size="sm"
                href={`${config2.baseUrl}/uploads/${openselfie}`}
                target="_blank"
              >
                View
              </Button>
              <img
                src={`${config2.baseUrl}/uploads/${openselfie}`}
                alt="id proof"
                style={{ width: "100%", height: "100%" }}
              />
              <div>
                <label for="exampleInputEmail1">Reason</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  // onChange={handleReasonselfie}
                  value={selfiemessage}
                  readOnly
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleselfieClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={OpenAddress}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          // aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Address Proof"}</DialogTitle>


          <DialogContent>
            <div><div class="form-group">
              <label for="exampleInputEmail1">Type</label>
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder=""
                value={Addresstype}
                readOnly
              />
            </div>{" "}
              <div class="form-group">
                <label for="exampleInputEmail1">{Addresstype} Name</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={AddressName}
                  readOnly
                />
              </div>{" "}
              <div class="form-group">
                <label for="exampleInputEmail1">{Addresstype} Number</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={AddressNumber}
                  readOnly
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Front image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${Addressfront}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${Addressfront}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Back Side Image</label>
                <Button
                  color="rose"
                  size="sm"
                  href={`${config2.baseUrl}/uploads/${Addressback}`}
                  target="_blank"
                >
                  View
                </Button>
                <img
                  src={`${config2.baseUrl}/uploads/${Addressback}`}
                  alt="id proof"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Reason</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  // onChange={handleidproof}
                  value={Addressreason}
                  readOnly
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    </div>
  );
}
