import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import dexbot from "../../ABI/dexbot.json";
import config from "../../actions/config";
import {

    getwithdrawLimit,
    updatewithdrawLimit
} from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";
import { idcrtnetwork } from "../../lib/history"

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        // getadminUsers,
        textDecoration: "none",
    },
};

// toaster config
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const initialFormValue = {
    name: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
    const customStyles = customStyle();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [adminnameset, setAdminName] = useState("");
    const [adminid, setadminid] = useState("");
    const [Account, setAccount] = useState()
    const [FormSubmitLoading, Set_FormSubmitLoading] = useState("")

    // console.log(adminid);
    console.log(adminnameset, "adminnamesetadminnamesetadminnameset");

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setAdminName(value);
        setValidateError({});
        let formDatass = { ...formData, ...{ [name]: value } };
        setFormData(formDatass);
    };

    const { name } = formData;

    useEffect(() => {
        acc();
    }, []);

    const acc = async () => {
        const web3 = new Web3(window.ethereum);
        let accounts = await web3.eth.requestAccounts();
        setAccount(accounts);
    };



    const handleFormSubmit = async (e) => {
        e.preventDefault();


        if (Account != config.admin_address) {
            toast.error("connect your valid account", toasterOption);
            return false;
        }
        const iscrtnet = await idcrtnetwork()
        // console.log(iscrtnet);
        if (iscrtnet == false) {
            return false
        }

        try {
            Set_FormSubmitLoading("processing")
            const web3 = new Web3(window.ethereum);

            function convert(n) {

                var sign = +n < 0 ? "-" : "",
                    toStr = n.toString();
                if (!/e/i.test(toStr)) {
                    return n;
                }
                var [lead, decimal, pow] = n
                    .toString()
                    .replace(/^-/, "")
                    .replace(/^([0-9]+)(e.*)/, "$1.$2")
                    .split(/e|\./);
                return +pow < 0
                    ? sign +
                    "0." +
                    "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
                    lead +
                    decimal
                    : sign +
                    lead +
                    (+pow >= decimal.length
                        ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
                        : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
            }

            const datacalcul = (1 / adminnameset) * 10 ** 6
            var amt = datacalcul.toString();
            amt = convert(amt);
            let decimal = Math.trunc(amt)




            const BEPContract = new web3.eth.Contract(
                dexbot,
                config.contract_address
            );

            await BEPContract.methods.tokenPriceUpdate(decimal).send({
                from: config.admin_address,
            });

            let reqData = {
                Limit: adminnameset,
                id: adminid,
            };
            // console.log(reqData, "adminid");
            const { status, message, error, result } = await updatewithdrawLimit(reqData);


            if (status) {
                toastAlert("success", message, "Admin");
                history.push("/dashboard");
                Set_FormSubmitLoading("done")
                //window.location=('/categorylist')
            } else if (error) {
                setValidateError(error);
                Set_FormSubmitLoading("done")
            }
        } catch (err) {
            console.log(err);
            toast.error("Transaction rejected by user", toasterOption);
            Set_FormSubmitLoading("done")
        }
    };



    const getadmindata = async () => {
        var result = await getwithdrawLimit();
        console.log(result, "resultss");
        if (result != null) {
            setAdminName(result.result[0]?.TokenPrice);
            setadminid(result.result[0]?._id);
        }
    };
    useEffect(() => {
        //logout(history)
        getadmindata();
    }, []);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form
                            className={classes.form}
                            onSubmit={handleFormSubmit}
                            noValidate
                        >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>
                                    Token Price Update
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            labelText={`1 QBT equal to ${adminnameset} BUSD`}
                                            onChange={onChange}
                                            name="name"
                                            value={adminnameset}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />

                                        {validateError.AdminName && (
                                            <span style={{ color: "red" }}>
                                                {validateError.AdminName}
                                            </span>
                                        )}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit" disabled={FormSubmitLoading == "processing"}>
                                    {FormSubmitLoading == "processing" && (
                                        <i
                                            class="fa fa-spinner fa-spin"
                                            aria-hidden="true"
                                            id="circle"
                                        ></i>
                                    )}
                                    Update
                                </Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
