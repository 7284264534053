import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getFaqList, NFCAppandRej, requestdata } from '../../actions/admin';
import Search from "@material-ui/icons/Search";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { toastAlert } from '../../lib/toastAlert'
import { TextField } from "@material-ui/core";
import ReactDatatable from "@ashvin27/react-datatable";
import * as moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
// import TextField from "@material-ui/core/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    // onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const history = useHistory();
    const customStyles = customStyle();
    const classesSearch = useToolbarStyles();
    // const [data, setData] = React.useState(0);
    // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);

    // const [withdraw, setwithdraw] = useState([]);
    const [responsive, setresponsive] = useState(true);
    const [searchid, setSearchid] = useState(0);
    const [userDetails, setUser] = useState([]);
    const [OpenSelfie, setOpenSelfie] = useState(false);

    // alert(OpenSelfie)

    const [paraddress, setparaddress] = useState()
    const [cmd, setcmt] = useState()


    const [cardNo, setcardno] = useState("")
    const [errordata, seterrordata] = React.useState({});

    const [ccNumber, setCcNumber] = useState("");


    const handleClose = (e) => {

        setOpenSelfie(false)
    };



    useEffect(() => {
        getAll();
    }, [searchid, OpenSelfie])




    const getAll = async () => {

        var reqdata = {
            search: searchid
        }
        console.log(reqdata);
        var res = await requestdata(reqdata);
        console.log(res);
        if (res?.result != null) {

            setUser(res.result);
        } else {

            setUser([])
        }

    }

    const opendata = (ads, cmd) => {
        setOpenSelfie(true)
        setparaddress(ads)
        setcmt(cmd)
    }



    const handlesubmit = async (status, address1) => {
        try {
            if (cardNo == "") {
                seterrordata({ empty: "Card Number Must Not be empty" })
                return false
            }
            const cardnumber = cardNo.split(" ").join("");
            const confirmnumber = ccNumber.split(" ").join("");
            if (cardnumber.length != 16 && confirmnumber.length != 16) {
                seterrordata({ length: "Card Number Must be 16 Digits" })
                return false
            }
            if (cardnumber != confirmnumber) {
                seterrordata({ mismatch: "Confirm Card Number Not Match" })
                return false
            }



            const reqdata = {
                status,
                address: address1,
                cardnumber: parseInt(cardnumber)
            }
            console.log(reqdata, "reqdata");
            const data = await NFCAppandRej(reqdata)
            console.log(data);
            if (data.status == true) {
                setOpenSelfie(false)
            }

            // window.location.reload()
        } catch (err) {
            console.log(err);
        }

    }


    // const isSelected = (name) => selected.indexOf(name) !== -1;


    const columns = [
        {
            key: "sno",
            text: "S.No",
            className: "userID",
            align: "center",
            sortable: false,
            cell: (record, index) => (
                <div>
                    <p align="center">{index + 1}</p>
                </div>
            ),
        }, {
            key: "userID",
            text: "User Address",
            className: "userID",
            align: "center",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{halfAddrShow(record.address)}</p>
                </div>
            ),
        },

        {
            key: "accumulationAmountDue",
            text: "Referal Address",
            className: "planAmount",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{halfAddrShow(record.ReferalId)}</p>
                </div>
            ),
        },



        // {
        //     key: "referealAmtBos",
        //     text: "Amount",
        //     className: "planAmount",
        //     align: "left",
        //     sortable: false,

        //     cell: (record) => (
        //         < div >
        //             {record.amount}
        //         </div >
        //     ),
        // },

        {
            key: "useremail",
            text: "Date",
            className: "userID",
            align: "left",
            sortable: false,
            cell: (record) => (
                <div>
                    <p align="center">{moment(record.createdAt).format('MMMM,Do YYYY, hh:mm A')}</p>
                </div>
            ),
        },
        {
            key: "action",
            text: "action",
            className: "action",
            align: "left",
            width: "10px",
            sortable: false,
            cell: (record) => (
                <div>
                    <Button onClick={() => opendata(record.address, "approve")} size="sm" color="success">Approve</Button>
                    <Button onClick={() => opendata(record.address, "reject")} size="sm" color="danger">Reject</Button>
                </div >
            ),
        },
        {
            key: "View",
            text: "View",
            className: "View",
            align: "left",
            width: "10px",
            sortable: false,
            cell: (record) => (
                <div>
                    <Link to={`/view-nfc/${record._id}`}><VisibilityIcon style={{ color: "#109ebf" }} /></Link>
                </div>
            ),
        },


    ];

    const halfAddrShow = (addr) => {
        if (addr) {
            return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
        } else {
            return "";
        }
    };

    function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }


    const configdata = {
        page_size: 5,
        length_menu: [5, 10, 20, 50],
        filename: "Users",
        no_data_text: "No user found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };

    const formatAndSetCcNumber = (e, val) => {
        console.log(val);
        const inputVal = e.target.value.replace(/ /g, ""); //remove all the empty spaces in the input
        let inputNumbersOnly = inputVal.replace(/\D/g, ""); // Get only digits

        if (inputNumbersOnly.length > 16) {
            //If entered value has a length greater than 16 then take only the first 16 digits
            inputNumbersOnly = inputNumbersOnly.substr(0, 16);
        }

        // Get nd array of 4 digits per an element EX: ["4242", "4242", ...]
        const splits = inputNumbersOnly.match(/.{1,4}/g);

        let spacedNumber = "";
        if (splits) {
            spacedNumber = splits.join(" "); // Join all the splits with an empty space
        }
        if (val == "first") {
            setcardno(spacedNumber);
        } else {
            setCcNumber(spacedNumber)

        }

        // Set the new CC number
    };


    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar
                    className={clsx(classesSearch.root, {
                        [classesSearch.highlight]: selected.length > 0,
                    })}
                >
                    <Typography
                        className={classesSearch.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >

                        NFC Card Request
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        label="Search User Address"
                        variant="outlined"
                        style={{ width: "700px" }}
                        onChange={(e) => setSearchid(e.target.value)}
                    />
                </Toolbar>

                <ReactDatatable
                    responsive={responsive}
                    config={configdata}
                    records={userDetails}
                    columns={columns}
                    onPageChange={pageChange()}
                />

            </Paper>
            <div>
                <div>
                    <Dialog
                        open={OpenSelfie}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        // aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        aria-labelledby="form-dialog-title"

                    // style={{ background: "green" }}
                    >
                        {cmd == "approve" ? <DialogTitle id="alert-dialog-slide-title" style={{ color: "green" }}>{"Are You Sure to approve ?"}</DialogTitle> :
                            <DialogTitle id="alert-dialog-slide-title" style={{ color: "red" }}>{"Are You Sure to reject ?"}</DialogTitle>}
                        <DialogContent>
                            <div>
                                <h4>Address:</h4><p>{paraddress}</p>

                            </div>
                            {cmd == "approve" ?
                                <div>
                                    <h4>Card Number:</h4>
                                    <TextField

                                        // label="Card Number"
                                        id="credit-card-input"
                                        placeholder="XXXX XXXX XXXX XXXX"
                                        maxlength="19"
                                        style={{ width: "300px" }}
                                        value={cardNo} onChange={(e) => formatAndSetCcNumber(e, "first")}
                                    />
                                    <p style={{ color: "red" }}>{errordata.empty == "" ? "" : errordata.empty}</p>
                                </div> : ""}  {cmd == "approve" ?
                                    <div>
                                        <h4>Re-Enter Card Number:</h4>
                                        <TextField

                                            // label="Card Number"
                                            id="credit-card-input"
                                            placeholder="XXXX XXXX XXXX XXXX"
                                            maxlength="19"
                                            style={{ width: "300px" }}
                                            value={ccNumber} onChange={(e) => formatAndSetCcNumber(e, "second")}
                                        />
                                        <p style={{ color: "red" }}>{errordata.mismatch == "" ? "" : errordata.mismatch}</p>
                                        <p style={{ color: "red" }}>{errordata.length == "" ? "" : errordata.length}</p>

                                    </div> : ""}
                        </DialogContent>
                        <DialogActions>
                            {cmd == "approve" ? <Button onClick={() => handlesubmit("approve", paraddress)} color="success">Approve</Button> : <Button onClick={() => handlesubmit("rejected", paraddress)} color="danger">reject</Button>}

                            <Button
                                onClick={handleClose}
                                color="primary">
                                Close
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        </div>
    );
} 