import React from 'react'
import { useHistory, useParams } from "react-router-dom";

import { verifyemail } from "../../actions/admin";

function Verifyemail() {
    const { userId, id } = useParams()
    console.log(userId, id);

    const verifydata121 = async () => {
        const req = {
            id: userId,
            otp: id
        }

        const verifydata = await verifyemail(req)

        console.log(verifydata, "yyyyyyy")
    }

    verifydata121()
    return (
        <>
            <center>
                <h3>successfully verified</h3>
            </center>
        </>
    )


}

export default Verifyemail


