// import package
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import config from "../../actions/config";
import { toast } from "react-toastify";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, InputLabel } from "@material-ui/core";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@material-ui/icons";

// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";

// import action
import { addNewAdmin } from "../../actions/admin";

// import lib
import routes from "../../routes";
import { toastAlert } from "../../lib/toastAlert";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  name: "",
  email: "",
  password: "",
  restriction: [], //restriction(path, isWriteAccess)
};

const useStyles = makeStyles(styles);

const AdminAdd = (props) => {
  const classes = useStyles();
  const history = useHistory();

  // state
  const [toched, setToched] = useState({});
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { name, email, password, restriction } = formValue;

  // function
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const handleRestriction = (data) => {
    let index = restriction.findIndex((el) => el.path == data.path);
    console.log(index);
    if (index >= 0) {
      if (data.isRestricted == "multi") {
        let newData = [];
        restriction.filter((el, key) => {
          let childData = data.child.find((item) => item.path == el.path);
          if (!(childData || key == index)) {
            newData.push(el);
          }
          console.log(newData, 'NewData');
        });
        console.log(newData, 'NewData');
        let formData = {
          ...formValue,
          ...{
            restriction: newData,
          },
        };
        setFormValue(formData);
        return;
      } else {
        let formData = {
          ...formValue,
          ...{
            restriction: restriction.filter((el, key) => key != index && el),
          },
        };
        setFormValue(formData);
        return;
      }
    } else {
      if (data.isRestricted == "multi") {
        let newRestriction = [
          {
            path: data.path,
            isWriteAccess: false,
          },
        ];

        data.child.filter((item) => {
          newRestriction.push({
            path: item.path,
            isWriteAccess: false,
          });
        });

        const newRestrictionValue = [...restriction, ...newRestriction];
        console.log("formData", newRestrictionValue);
        let formData = {
          ...formValue,
          ...{
            restriction: newRestrictionValue,
          },
        };

        setFormValue(formData);
        return;
      } else {
        const newRestriction = [
          ...restriction,
          ...[
            {
              path: data.path,
              isWriteAccess: false,
            },
          ],
        ];
        let formData = {
          ...formValue,
          ...{
            restriction: newRestriction,
          },
        };
        setFormValue(formData);
        return;
      }
    }
  };

  const handleWriteAccess = (data) => {
    let index = restriction.findIndex((el) => el.path == data.path);
    if (index >= 0) {
      let formData = {
        ...formValue,
        ...{
          restriction: restriction.filter((el, key) => {
            if (key == index) {
              el["isWriteAccess"] = !el.isWriteAccess;
            }
            return el;
          }),
        },
      };
      setFormValue(formData);
      return;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();

    let EmailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isValidLength = /^.{8,16}$/;

    if (accounts != config.admin_address) {
      toast.error("Connect your valid account", toasterOption);
      return false;
    }
    if (!EmailRegex.test(email)) {
      toast.error("Please enter a valid Email", toasterOption);
      return false;
    }

    if (!name) {
      toast.error("Please enter name", toasterOption);
      return false;
    }
    if (!email) {
      toast.error("Please enter email", toasterOption);
      return false;
    }
    if (!password) {
      toast.error("Please enter password", toasterOption);
      return false;
    }
    if (!isValidLength.test(password)) {
      toast.error("Password must be 8-16 Characters Long.", toasterOption);
      return false;
    }
    let reqData = {
      name,
      email,
      password,
      restriction,
    };
    console.log(reqData, "Reqqq");
    try {
      const data = await addNewAdmin(
        reqData
      );
      console.log(data, "statusstatus");
      if (data.loading == true) {
        toastAlert("success", "User Added successfully", "addAdmin");
        history.push("/list");
      } else {
        toastAlert("error", "Email Already Exits", "addAdmin");
      }
    } catch (err) { }
  };
  const renderRestrictionList = () => {
    let arr = [];
    routes &&
      routes.length > 0 &&
      routes.map((el) => {
        console.log(el, 'elelelelel');
        if (el.isSideMenu) {
          if (el.type == "multi") {
            el.child.map((item) => {
              console.log(restriction, item.path, 'TwoTwoTwo');
              if (item.isRestricted != "common") {
                let isChecked = false,
                  isWriteChecked = false,
                  isWriteDisabled = true;
                let restrictionData = restriction.find(
                  (restItem) => restItem.path == item.path
                );
                console.log(restrictionData, 'restrictionData');
                if (restrictionData) {
                  isChecked = true;
                  isWriteDisabled = false;
                  isWriteChecked = restrictionData.isWriteAccess;
                }

                arr.push(
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={isChecked}
                          onClick={() => handleRestriction(item)}
                        />
                      }
                      label={item.name}
                    />
                  </div>
                );
              }
            });
          } else {
            let isChecked = false,
              isWriteChecked = false,
              isWriteDisabled = true;
            let restrictionData = restriction.find(
              (restItem) => restItem.path == el.path
            );
            if (restrictionData) {
              isChecked = true;
              isWriteDisabled = false;
              isWriteChecked = restrictionData.isWriteAccess;
            }

            arr.push(
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={isChecked}
                      onClick={() => handleRestriction(el)}
                    />
                  }
                  label={el.name}
                />
              </div>
            );
          }
        }
      });
    return arr;
  };

  useEffect(() => {
    if (routes && routes.length > 0) {
      let commonData = [];
      routes.filter((el) => {
        if (el.isRestricted == "common") {
          commonData.push({
            path: el.path,
            isWriteAccess: false,
          });
        }
      });

      if (commonData && commonData.length > 0) {
        let formData = {
          ...formValue,
          ...{
            restriction: commonData,
          },
        };
        setFormValue(formData);
      }
    }
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Add New Admin</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.name && (
                      <span className={classes.textDanger}>
                        {validateError.name}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.email && (
                      <span className={classes.textDanger}>
                        {validateError.email}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Password"
                      type="password"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.email && (
                      <span className={classes.textDanger}>
                        {validateError.email}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel>Restriction</InputLabel>
                    {renderRestrictionList()}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  ADD
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AdminAdd;
